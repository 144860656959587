import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdDocument, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { MdModeEdit } from "react-icons/md";
import { IoCloseCircleOutline,IoAddCircleOutline } from "react-icons/io5";

import { IoIosSearch } from "react-icons/io";
import { IoMdSave } from "react-icons/io";
import { ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { darkBlue } from '../../styles/colors';
import Renderif from '../../utils/renderif';
import { AuthContext } from '../../context/auth_context';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import { GlobalContext } from '../../context/global_context';
import NuLoading from '../../components/nu_loading';
import { useFilePicker } from 'use-file-picker';
import CheckBox from '../../components/nu_checkbox';
import NuComboBox from '../../components/nu_combobox';
import { BsThreeDotsVertical } from 'react-icons/bs';
export default function PerfilProfesionalView() {
    const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
   
    console.log(user_data)
    
    var nurse_data_aux = user_data?.idUserType === 1 ? user_data?.nurseData : user_data?.idUserType === 3 ?  user_data?.tcaeData : {}

    const navigate = useNavigate()
    const {notify} = useContext(NotificationContext);
    const {specialization, centerType, professionalField, assistanceUnit, getRating} = useContext(GlobalContext)
    const { openFilePicker, filesContent, loading, plainFiles } = useFilePicker({
        accept: '*',
      });
    const [state, setState] = useState({

        show_menu:false,

        assistanceUnit:[],
        professionalField:[],

        loading:true,
        editing_general:false,
        editing_preferences:null,
        user:null,
        // user:{
        //     name:user_data?.name,
        //     phone: user_data?.phone_number,
        //     nif: user_data?.cif,
        //     postal_code: user_data?.postal_code,
        // },
        nurse_data:{
            ...nurse_data_aux
        },
        preferredCenter:user_data?.preferredCenter,
        preferredProfessionalField: user_data?.preferredProfessionalField,
        preferredAssistanceUnit:user_data?.preferredAssistanceUnit,
        educationAssistanceUnit:user_data?.educationAssistanceUnit,
        educationProfessionalField: user_data?.educationProfessionalField,
        assistanceUnitExperience: user_data?.assistanceUnitExperience,
        professionalFieldExperience:user_data?.professionalFieldExperience,

        password:'',
        passwordConfirmed:'',
        errors:{},
        provincias: [
            {
                provincia: 'Andalucía',
                colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
            },
            {
                provincia: 'Aragón',
                colegios: ['Zaragoza', 'Teruel', 'Huesca']
            },
            {
                provincia: 'Principado de Asturias',
                colegios: ['Asturias']
            },
            {
                provincia: 'Illes Balears',
                colegios: ['Illes Balears']
            },
            {
                provincia: 'Canarias',
                colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
            },
            {
                provincia: 'Cantabria',
                colegios: ['Cantabria']
            },
            {
                provincia: 'Castilla y León',
                colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
            },
            {
                provincia: 'Castilla y la Mancha',
                colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
            },
            {
                provincia: 'Cataluña',
                colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
            },
            {
                provincia: 'Comunidad Valenciana',
                colegios: ['Alicante', 'Valencia', 'Castellón']
            },
            {
                provincia: 'Extremadura',
                colegios: ['Cáceres','Badajoz']
            },
            {
                provincia: 'Galicia',
                colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
            },
            {
                provincia: 'Murcia',
                colegios: ['Murcia']
            },
            {
                provincia: 'Madrid',
                colegios: ['Madrid']
            },
            {
                provincia: 'Navarra',
                colegios: ['Navarra']
            },
            {
                provincia: 'País Vasco',
                colegios: ['Bizcaia','Gipuzkoa','Araba']
            },
            {
                provincia: 'La Rioja',
                colegios: ['La Rioja']
            },
            {
                provincia: 'Ceuta',
                colegios: ['Ceuta']
            }
            ,
            {
                provincia: 'Melilla',
                colegios: ['Melilla']
            }
            
        ],
        
    })


    
    useEffect(()=>{
        const init = async () => {
            if(user_data !== null && user_data !== undefined){

                setState({...state, loading:true})
                

                let nurse_data_aux = user_data?.idUserType === 1 ? user_data?.nurseData : user_data?.idUserType === 3 ?  user_data?.tcaeData : {}

                let user={
                    name:user_data?.name,
                    phone: user_data?.phone_number,
                    nif: user_data?.cif,
                    postal_code: user_data?.postal_code,
                }
               
                state.nurse_data={
                    ...nurse_data_aux
                }
                state.user = user
                

                
                let _assistanceUnit = await Service('GET', `assistanceUnit/getAll`);
                let _professionalField = await Service('GET', `professionalField/getAll`);
            
        
            
                setState({...state, loading:false, 
                    assistanceUnit:_assistanceUnit.data.list.filter((item) => item.id !== 16),
                    professionalField:_professionalField.data.list
                })
            }
         
        };
        init();
        return () => {}
    },[user_data])

    useEffect( ()=>{


        const init = async () => {

            if(filesContent.length > 0 && state.active_file_type !== null){     
           
                setState({...state, loading:true})
                await updateUserPictue(plainFiles[0])
                await setUserPicture();
         
                setState({...state, loading:false})
                
            }

        }
        init();
        

        return () => {}

     
    },[filesContent])

    // useEffect(()=>{
       
      
    //     const init = () => {
    //         if(user_data !== null && user_data !== undefined){
    //             console.log("NOMBRE" + user_data?.name)

    //         // let nurse_data_aux = user_data.idUserType === 1 ? user_data.nurseData : user_data.idUserType === 3 ?  user_data.tcaeData : {}
    //         let user={
    //             name:user_data?.name,
    //             phone: user_data?.phone_number,
    //             nif: user_data?.cif,
    //             postal_code: user_data?.postal_code,
    //         }
    //         console.log(user);
    //         // state.nurse_data={
    //         //     ...nurse_data_aux
    //         // }

    //         state.user = user
    //         setState({
    //             ...state
    //            })
    //         console.log(state.user)
    //         }
    //     }
    //     init();
    //     return () => {}


    //     // console.log(state.nurse_data)
    //     // state.preferredCenter:user_data?.preferredCenter,
    //     // state.preferredProfessionalField: user_data?.preferredProfessionalField,
    //     // state.preferredAssistanceUnit:user_data?.preferredAssistanceUnit,
    //     // state.educationAssistanceUnit:user_data?.educationAssistanceUnit,
    //     // state.educationProfessionalField: user_data?.educationProfessionalField,
    //     // state.assistanceUnitExperience: user_data?.assistanceUnitExperience,
    //     // state.professionalFieldExperience:user_data?.professionalFieldExperience,
        
    
    // },[user_data])


   
 

    const updateNurseData = async () => {
        let body = state.nurse_data;
    
        let _update = await Service('PUT', 'nurseData/update', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();
    }
    const updateTcaeData = async () => {
        let body = state.nurse_data;
   
        let _update = await Service('PUT', 'tcaeData/update', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();
        
    }
    const updatePreferredCenter =  async () => {
        let body = {
            preferredCenterList:[]
        }
        state.preferredCenter.map((center, i)=>{
            body.preferredCenterList.push({
                idUser:center.idUser,
                idCenterType:center.idCenterType
            })
        })
        let _update = await Service('POST', 'preferredCenter/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updatePreferredProfessionalField =  async () => {
        let body = {
            preferredProfessionalFieldList:[]
        }
        state.preferredProfessionalField.map((item, i)=>{
           
            body.preferredProfessionalFieldList.push({
                idUser:item.idUser,
                idProfessionalField:item.idProfessionalField
            })
        })
        let _update = await Service('POST', 'preferredProfessionalField/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updatePreferredAssistanceUnit=  async () => {
        let body = {
            preferredAssistanceUnitList:[]
        }
        state.preferredAssistanceUnit.map((item, i)=>{
           
            body.preferredAssistanceUnitList.push({
                idUser:item.idUser,
                idAssistanceUnit:item.idAssistanceUnit
            })
        })
        let _update = await Service('POST', 'preferredAssistanceUnit/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateEducationAssistanceUnit=  async () => {
        let body = {
            educationAssistanceUnitList:[]
        }
        state.educationAssistanceUnit.map((item, i)=>{
           
            body.educationAssistanceUnitList.push({
                idUser:item.idUser,
                idAssistanceUnit:item.idAssistanceUnit
            })
        })
        let _update = await Service('POST', 'educationAssistanceUnit/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateEducationProfessionalField=  async () => {
        let body = {
            educationProfessionalFieldList:[]
        }
        state.educationProfessionalField.map((item, i)=>{
           
            body.educationProfessionalFieldList.push({
                idUser:item.idUser,
                idProfessionalField:item.idProfessionalField
            })
        })
        let _update = await Service('POST', 'educationProfessionalField/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateAssistanceUnitExperience=  async () => {
        let body = {
            assistanceUnitExperienceList:[]
        }
        state.assistanceUnitExperience.map((item, i)=>{
           
            body.assistanceUnitExperienceList.push({
                idUser:item.idUser,
                idAssistanceUnit:item.idAssistanceUnit,
                yearsOfExperience:item.yearsOfExperience
            })
        })
        let _update = await Service('POST', 'assistanceUnitExperience/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateProfessionalFieldExperience = async () => {
        let body = {
            professionalFieldExperienceList:[]
        }
        state.professionalFieldExperience.map((item, i)=>{
         
           
            body.professionalFieldExperienceList.push({
                idUser:item.idUser,
                idProfessionalField:item.idProfessionalField,
                yearsOfExperience:item.yearsOfExperience
            })
        })
        let _update = await Service('POST', 'professionalFieldExperience/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }



    const nurse_data = [
        {
            title:'Años de experiencia que tienes en la profesión',
            tags:[]
        },
        {
            title:'Especialización EIR',
            tags:[]
        },
        {
            title:'Centros donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Ámbitos profesionales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Unidades Asistenciales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Dispones de formación tipo master o postgrado relacionados con las siguientes unidades asistenciales',
            tags:[]
        },
        {
            title:'Dispones de formación tipo master o postgrado relacionados con los siguientes ámbitos profesionales',
            tags:[]
        },
        // {
        //     title:'En qué Unidades Asistenciales tienes experiencia',
        //     tags:[]
        // },
        // {
        //     title:'En qué  ámbitos profesionales tienes experiencia',
        //     tags:[]
        // }
    
    ]
    const tcae_data = [
        {
            title:'Años de experiencia que tienes en la profesión',
            tags:[]
        },
        {
            title:'',
            tags:[]
        },

        {
            title:'Centros donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Ámbitos profesionales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Unidades Asistenciales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'',
            tags:[]
        },
        {
            title:'',
            tags:[]
        },
        // {
        //     title:'En qué Unidades Asistenciales tienes experiencia',
        //     tags:[]
        // },
        // {
        //     title:'En qué  ámbitos profesionales tienes experiencia',
        //     tags:[]
        // }
    
    ]
    const profile_data = () => {
        if(user_data?.idUserType === 1){
            return nurse_data
        }
        if(user_data?.idUserType === 3){
            return tcae_data
        }
    }
    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$/;
        // var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const validator = () => {

        state.errors = {}

        if(!checkPassword(state.passwordConfirmed)){
            state.errors.password = 'Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)'
        }
        
        // if(state.passwordConfirmed !== state.password){
        //     state.errors.confirmPassword = 'Las contraseñas no coinciden'
        // }
       
        
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onUpdateProfile = async () => {

       
        
        let body = state.user;
      
     
        let update = await Service('PUT', 'user/updateProfessional', body );
        if(user_data?.idUserType === 1){   
            let _update = await Service('PUT', 'nurseData/update', state.nurse_data );
            
        }
      
   
        if(user_data?.idUserType === 3){   
            let _update = await Service('PUT', 'tcaeData/update', state.nurse_data );
       
        }
   
        if(update.status === 201){
            notify({title:"Información", message:'Tu datos han sido actualizados', type:'success'})
        }else{
            notify({title:"Información", message:'No ha sido posible actualizar tus datos.', type:'error'})
        }
        
        if(state.passwordConfirmed !== ''){

            body = {
                password:state.password,
                new_password:state.passwordConfirmed
            }

            if(!validator()){
        
                return;
            }
            let update_password = await Service('PUT', 'user/updatePassword', body );
        
            if(update_password.status === 201){
                notify({title:"Contraseña", message:'Tu contraseña ha sido actualizada', type:'success'})
            }else{
                notify({title:"Contraseña", message:'No ha sido posible cambiar tu contraseña', type:'error'})
            }
            setState({
                ...state, 
                password:'',
                passwordConfirmed:''
            })
            

        }
        getUserDateNurse()
    
    }
    const assistanceUnitOptionChecked = (value, option) =>{

        let _aux = state.assistanceUnitExperience?.filter((item)=>{
            if(item.idAssistanceUnit === option.id && item.yearsOfExperience === value){
                return true;
            }
            return false;
        })

        if(_aux?.length > 0){
            return true
        }
        return false

    }
    const optionChecked = (value, option) =>{

        let _aux = state.professionalFieldExperience?.filter((item)=>{
            if(item.idProfessionalField === option.id && item.yearsOfExperience === value){
                return true;
            }
            return false;
        })

        if(_aux?.length > 0){
            return true
        }
        return false

    }

    const yearsExperienceOptions = [
        {
            id:0,
            desctiprion:'No tengo experiencia'
        },
        {
            id:1,
            desctiprion:'Menos de un año'
        },
        {
            id:2,
            desctiprion:'Mas de 1 año'
        },

    ]


  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>

    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ProfesionalSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='perfil'/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user_data?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>{getRating().rate} ( {getRating().valoraciones} reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto p-1 p-md-3'>

                    <div className='d-flex flex-column justify-content-start align-items-start p-2'>
                        <div className='f-22 bold mb-3 d-block d-md-block'>Mi Perfil</div>
                   
                        <div className='d-flex d-md-none flex-column align-items-center'>
                            <div className='avatar avatar--small' style={{backgroundImage: `url(${user?.picture})`}}></div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                openFilePicker()
                            }}>
                                <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                            </div>
                        </div>
                    </div>


                <div className='d-flex'>

                    <div className='d-none d-xl-flex flex-column align-items-center pe-3'>
                        <div className='avatar avatar--big' style={{backgroundImage: `url(${user?.picture})`}}></div>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            openFilePicker()
                        }}>
                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                        </div>
                    </div>


                    <div className='col'>

                        <div className='bg-gray r-10 p-2 p-md-3'>

                            <div className='d-flex'>
                                <div className='f-22 bold mb-3'>Sobre ti</div>
                                <div className='col'></div>

                                <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                    setState({...state, editing_general:!state.editing_general, errors: {}})
                                }}>
                                    <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                    <div className='nu-icon nu-icon--mid' onClick={()=>{

                                    }}>
                                        <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                
                                <div className='col-6 col-md'>
                                    <div className='f-16 bold'>Nombre Completo</div>
                                </div>

                                <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} defaultValue={user_data?.name} onChange={(evt)=>{
                                        state.user.name = evt.currentTarget.value;

                                    }} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                </div>

                                <div className='col-6 col-md mt-2 mt-md-0'>
                                    <div className='f-16 ps-md-5 bold'>{user?.idUserType === 1 ? 'Colegio' : 'Comunidad Autónoma'}</div>
                                </div>

                                <div className='col-6 col-md mt-2  mt-md-0'>

                                    <Renderif isTrue={user?.idUserType === 1}>
                                      
                                       
                                        <select disabled={!state.editing_general} value={state.nurse_data?.college} onChange={(evt)=>{

                                                state.nurse_data.college = evt.currentTarget.value;
                                                setState({...state})
                                        
                                            
                                                
                                            }}  className={`nu-select nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>
                                            <option value="">Seleccionar</option>
                                            {
                                                state.provincias.filter((item) => item.provincia === state.nurse_data?.collegeProvince)[0]?.colegios.map((item, i)=>{
                                                  
                                                    return(
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                    
                                        </select>

                                    </Renderif>
                               
                                
                        
                                    <Renderif isTrue={user?.idUserType !== 1}>
                                
                                        <select disabled={!state.editing_general} value={state.nurse_data?.province} onChange={(evt)=>{

                                                state.nurse_data.province = evt.currentTarget.value;
                                                setState({...state})
                                        
                                            
                                                
                                            }}  className={`nu-select nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>
                                            <option value="">Seleccionar</option>
                                            {
                                                state.provincias.map((item, i)=>{
                                                    return(
                                                        <option key={i} value={item.provincia}>{item.provincia}</option>
                                                    )
                                                })
                                            }
                                    
                                        </select>
                                    </Renderif>
                               
                                
                                </div>

                                
                            </div>

                            <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                <div className='col-6 col-md'>
                                    <div className='f-16 bold'>Teléfono</div>
                                </div>
                                <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} defaultValue={user_data?.phone_number} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.user.phone = evt.currentTarget.value;
                                        
                                    }}></input>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0'>
                                    <div className='f-16 ps-md-5 bold'>DNI</div>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0'>
                                <input disabled={!state.editing_general} defaultValue={user_data?.cif} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.user.nif = evt.currentTarget.value;
                                        
                                    }}></input>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                <div className='col-6 col-md'>
                                    <div className='f-16 bold'>Correo electrónico</div>
                                </div>
                                <div className='col-6 col-md'>
                                    <input disabled defaultValue={user_data?.email}  className={`nu-input--light p-2 nu-input--disabled`}></input>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <div className='f-16 ps-md-5 bold'>Contraseña actual:</div>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <input disabled={!state.editing_general} value={state.password} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.password = evt.currentTarget.value;
                                        setState({...state})
                                    }}></input>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                <div className='col-6 col-md'>
                                    <div className='f-16 bold'>Código postal</div>
                                </div>
                                <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} defaultValue={user_data?.postal_code}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.user.postal_code = evt.currentTarget.value;
                                        
                                    }}></input>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <div className='f-16 ps-md-5 bold'>Nueva contraseña</div>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <input disabled={!state.editing_general}  value={state.passwordConfirmed}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                     state.passwordConfirmed = evt.currentTarget.value;
                                     setState({...state})
                                }}></input>
                                  <div className='text-danger f-12'>{state.errors?.password}</div>
                                </div>
                                
                            </div>

                            <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                <div className='col-6 col-md'>
                                    <Renderif isTrue={user_data?.idUserType === 1}>
                                        <div className='f-16  bold'>No. de Colegiado/a</div>
                                    </Renderif>
                                </div>
                                <div className='col-6 col-md '>
                                    <Renderif isTrue={user_data?.idUserType === 1}>
                                    <input disabled={!state.editing_general} defaultValue={state.nurse_data?.collegiateNumber}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                     state.nurse_data.collegiateNumber = evt.currentTarget.value;
                                     setState({...state})
                                }}></input>
                                    </Renderif>
                                </div>
                                
                                <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <div className='f-16 ps-md-5 bold'>Documentos</div>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        navigate('/profesional/documentacion')
                                    }}>
                                        <div className='nu-icon nu-icon--mid'>
                                            <IoMdDocument size={30}></IoMdDocument>
            
                                        </div>
                                        <div className='nu_btn-text f-14'>Ver documentos</div>
                                    </div>
                                </div>
                            </div>

                            <Renderif isTrue={user?.idUserType === 1}>
                                <div className='d-flex flex-wrap justify-content-center align-items-center mt-md-2'>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <div className='f-16  bold'>Comunidad Autónoma</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>

                                    
                                        <select disabled={!state.editing_general} defaultValue={user_data?.idUserType === 1 ? state.nurse_data?.collegeProvince : state.nurse_data?.province } onChange={(evt)=>{

                                        
                                        if(user_data?.idUserType === 1){
                                            state.nurse_data.collegeProvince = evt.currentTarget.value;
                                            // state.nurse_data.province = evt.currentTarget.value; 
                                        }else{
                                            state.nurse_data.province = evt.currentTarget.value; 
                                        }
                                        setState({...state})
                                        
                                            


                                        }}  className={`nu-select nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>
                                        <option value="" disabled>Seleccionar</option>
                                        {
                                            state.provincias.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.provincia}>{item.provincia}</option>
                                                )
                                            })
                                        }
                                
                                    </select>
                                    </div>


                                    <div className='col-6 col-md mt-2 mt-md-0'></div>
                                    <div className='col-6 col-md mt-2 mt-md-0'></div>

                                    
                                </div>
                            </Renderif>

                            <div className='d-flex d-md-none flex-wrap justify-content-center align-items-center'>
                               
                                <div className='col-6 col-md'>
                                    <div className='f-16 ps-md-5 bold'>Contraseña actual:</div>
                                </div>
                                <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} value={state.password} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.password = evt.currentTarget.value;
                                        setState({...state})
                                    }}></input>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0'>
                                    <div className='f-16 ps-md-5 bold'>Nueva contraseña</div>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0'>
                                    <input disabled={!state.editing_general}  value={state.passwordConfirmed}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                     state.passwordConfirmed = evt.currentTarget.value;
                                     setState({...state})
                                }}></input>
                                  <div className='text-danger f-12'>{state.errors?.password}</div>
                                </div>
                            </div>

                            <div className='d-flex d-md-none flex-wrap justify-content-center align-items-center  mt-2'>
                            
                                
                                <div className='col-6 col-md mt-2 mt-md-0'>
                                    <div className='f-16 ps-md-5 bold'>Documentos</div>
                                </div>
                                <div className='col-6 col-md mt-2 mt-md-0'>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        navigate('/profesional/documentacion')
                                    }}>
                                        <div className='nu-icon nu-icon--mid'>
                                            <IoMdDocument size={30}></IoMdDocument>
            
                                        </div>
                                        <div className='nu_btn-text f-14'>Ver documentos</div>
                                    </div>
                                </div>
                            </div>

                            <Renderif isTrue={state.editing_general}>
                                <div className='d-flex mt-4'>
                                    
                                    <div className='col'></div>

                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        // setState({...state, show_turno_detail:!state.show_turno_detail})
                                        onUpdateProfile();
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Guardar</div>
                                    </div>
                                </div>
                            </Renderif>

                        </div>

                        {
                            profile_data()?.map((item,j)=>{
                                if(user_data.idUserType === 3 && j === 1)return;
                                if(user_data.idUserType === 3 && j === 5)return;
                                if(user_data.idUserType === 3 && j === 6)return;
                                if(j === 2)return;
                                if(j === 3)return;
                                if(j === 4)return;

                                return(

                                    <div key={j} className='bg-gray r-10 p-3 mt-3'>

                                        <div className='d-flex'>
                                            <div className='f-16 bold mb-3'>{item.title}</div>
                                            <div className='col'></div>
            
                                            <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                                if(state.editing_preferences === j){
                                                    setState({...state, editing_preferences:null}) 
                                                    return;
                                                }
                                                setState({...state, editing_preferences:j})
                                            }}>
                                                <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                                <div className='nu-icon nu-icon--mid cursor-pointer'>
                                                    <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap'>
                                           {
                                            item.tags.map((tag,i)=>{
                                                
                                                return (
                                                    <div key={i} className='nu-tag nu-tag--active'>
                                                        <div>{tag}</div>

                                                        <Renderif isTrue={state.editing_preferences === j}>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoCloseCircleOutline size={24}></IoCloseCircleOutline>
                                                        </div>
                                                        </Renderif>
                                        
                                                    </div>
                                                )
                                            })
                                           }
                                        </div>

                                        <Renderif isTrue={state.editing_preferences === j}>
                                            
                                            <Renderif isTrue={j === 0}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                    <div className={`nu-tag cursor-pointer ${state.nurse_data.yearsOfExperience === '0' ? 'nu-tag--active':''}`} onClick={()=>{
                                                        
                                                        state.nurse_data.yearsOfExperience = '0'
                                                        setState({...state})
                                                    
                                                    }}>
                                                        <div>Sin experiencia</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                    <div className={`nu-tag cursor-pointer ${state.nurse_data.yearsOfExperience === '1' ? 'nu-tag--active':''}`} onClick={()=>{
                                                        state.nurse_data.yearsOfExperience = '1'
                                                      
                                                        setState({...state})
                                                    }}>
                                                        <div>1-5 años</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                    <div className={`nu-tag cursor-pointer ${state.nurse_data.yearsOfExperience === '2' ? 'nu-tag--active':''}`} onClick={()=>{
                                                        state.nurse_data.yearsOfExperience = '2'
                                                      
                                                        setState({...state})
                                                    }}>
                                                        <div>6-10 años</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                    <div className={`nu-tag cursor-pointer ${state.nurse_data.yearsOfExperience === '3' ? 'nu-tag--active':''}`} onClick={()=>{
                                                        state.nurse_data.yearsOfExperience = '3'
                                                      
                                                        setState({...state})
                                                    }}>
                                                        <div>{'> 10 años'}</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 1 && user_data.idUserType === 1}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {specialization.map((specialization, k)=>{
                                                    return (
                                                        <div key={k} className={`nu-tag cursor-pointer ${state.nurse_data.idSpecialization === specialization.id ? 'nu-tag--active':''}`} onClick={()=>{
                                                            state.nurse_data.idSpecialization = specialization.id
                                                            setState({...state})
                                                        
                                                        }}>
                                                            <div>{specialization.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 2}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                
                                                {centerType?.map((center, l)=>{
                                               
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.preferredCenter?.some((item) => item.idCenterType === center.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            

                                                            if(state.preferredCenter?.some((item) => item.idCenterType === center.id)){
                                                                state.preferredCenter = state.preferredCenter.filter((item)=> item.idCenterType !== center.id)
                                                            }else{

                                                                if(center.id === 1){
                                                                    state.preferredCenter = [];
                                                                }else{
                                                                    state.preferredCenter = state.preferredCenter.filter((item)=> item.idCenterType !== 1)
                                                                }

                                                                state.preferredCenter.push({
                                                                    id:0,
                                                                    idCenterType:center.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                            // state.nurse_data.idSpecialization = specialization.id
                                                            // setState({...state})
                                                        
                                                        }}>
                                                            <div>{center.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 3}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                {professionalField.filter((item) => item.id !== 7).map((field, l)=>{
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.preferredProfessionalField?.some((item) => item.idProfessionalField === field.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.preferredProfessionalField?.some((item) => item.idProfessionalField === field.id)){
                                                                state.preferredProfessionalField = state.preferredProfessionalField.filter((item)=> item.idProfessionalField !== field.id)
                                                            }else{

                                                                if(field.id === 1){
                                                                    state.preferredProfessionalField = [];
                                                                }else{
                                                                    state.preferredProfessionalField = state.preferredProfessionalField.filter((item)=> item.idProfessionalField !== 1)
                                                                }

                                                                state.preferredProfessionalField.push({
                                                                    id:0,
                                                                    idProfessionalField:field.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{field.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 4}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {assistanceUnit.filter((item) => item.id !== 16 && item.id !== 14).map((unit, l)=>{

                                               
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.preferredAssistanceUnit?.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.preferredAssistanceUnit?.some((item) => item.idAssistanceUnit === unit.id)){
                                                                state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                                                            }else{


                                                                if(unit.id === 1){
                                                                    state.preferredAssistanceUnit = [];
                                                                }else{
                                                                    state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== 1)
                                                                }
                                                                

                                                                state.preferredAssistanceUnit.push({
                                                                    id:0,
                                                                    idAssistanceUnit:unit.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{unit.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 5 && user_data.idUserType === 1}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {assistanceUnit.map((unit, l)=>{
                                                    if(l === 0) return;
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.educationAssistanceUnit?.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.educationAssistanceUnit?.some((item) => item.idAssistanceUnit === unit.id)){
                                                                state.educationAssistanceUnit = state.educationAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                                                            }else{

                                                                state.educationAssistanceUnit.push({
                                                                    id:0,
                                                                    idAssistanceUnit:unit.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{unit.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 6 && user_data.idUserType === 1}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                {professionalField.map((field, l)=>{

                                                    if(l===0)return;
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.educationProfessionalField?.some((item) => item.idProfessionalField === field.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.educationProfessionalField.some((item) => item.idProfessionalField === field.id)){
                                                                state.educationProfessionalField = state.educationProfessionalField.filter((item)=> item.idProfessionalField !== field.id)
                                                            }else{

                                                                state.educationProfessionalField.push({
                                                                    id:0,
                                                                    idProfessionalField:field.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{field.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 7}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {assistanceUnit.map((unit, l)=>{

                                                    if(unit.id === 1){return}
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.assistanceUnitExperience?.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.assistanceUnitExperience?.some((item) => item.idAssistanceUnit === unit.id)){
                                                                state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item)=> item.idAssistanceUnit !== unit.id)
                                                            }else{


                                                                // if(unit.id === 1){
                                                                //     state.assistanceUnitExperience = [];
                                                                // }else{
                                                                //     state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item)=> item.idAssistanceUnit !== 1)
                                                                // }
                                                                

                                                                state.assistanceUnitExperience.push({
                                                                    id:0,
                                                                    assistanceUnitExperience:'1',
                                                                    idAssistanceUnit:unit.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{unit.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 8}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                {professionalField.map((field, l)=>{

                                                    if(field.id === 1) {return;}
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag cursor-pointer ${state.professionalFieldExperience?.some((item) => item.idProfessionalField === field.id) ? 'nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.professionalFieldExperience?.some((item) => item.idProfessionalField === field.id)){
                                                                state.professionalFieldExperience = state.professionalFieldExperience.filter((item)=> item.idProfessionalField !== field.id)
                                                            }else{

                                                     
                                                                state.professionalFieldExperience.push({
                                                                    id:0,
                                                                    yearsOfExperience:'1',
                                                                    idProfessionalField:field.id,
                                                                    idUser:user_data.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{field.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>
                                          

                                        </Renderif>

                                        <Renderif isTrue={state.editing_preferences === j}>
                                            <div className='d-flex'>
                                                
                                                <div className='col'></div>

                                                <div className='nu_btn nu_btn--primary' onClick={()=>{

                                                    if(j < 2){
                                                        if(user_data.idUserType === 1){
                                                            updateNurseData();
                                                        }
                                                        if(user_data.idUserType === 3){
                                                            updateTcaeData();
                                                        }
                                                       
                                                    }
                                                    if(j === 2){
                                                        updatePreferredCenter();
                                                    }
                                                    if(j === 3){
                                                        updatePreferredProfessionalField();
                                                    }
                                                    if(j === 4){
                                                        updatePreferredAssistanceUnit();
                                                    }
                                                    if(j === 5){
                                                        updateEducationAssistanceUnit();
                                                    }
                                                    if(j === 6){
                                                        updateEducationProfessionalField();
                                                    }
                                                    if(j === 7){
                                                        updateAssistanceUnitExperience();
                                                    }
                                                    if(j === 8){
                                                        updateProfessionalFieldExperience();
                                                    }
                                                    
                                                
                                                }}>
                                                    <div className='nu-icon nu-icon--mid me-1'>
                                                        <IoMdSave size={18}></IoMdSave>
                                                    </div>
                                                    <div className='nu_btn-text'>Guardar</div>
                                                </div>
                                            </div>
                                        </Renderif>
            
                                    </div>
                                )
                            })
                        }

                        <div className='bg-gray r-10 p-3 mt-3'>

                            <div className='d-flex'>
                                <div className='f-16 bold mb-3'>En qué Unidades Asistenciales tienes experiencia</div>
                                <div className='col'></div>

                                <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                    if(state.editing_preferences === 'UAE'){
                                        setState({...state, editing_preferences:null}) 
                                        return;
                                    }
                                    setState({...state, editing_preferences:'UAE'})
                                }}>
                                    <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                                        <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                    </div>
                                </div>
                            </div>
                                
                            <Renderif isTrue={state.editing_preferences === 'UAE'}>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='col px-3 px-md-5'>
                                    <CheckBox onChange={(evt)=>{
                                    
                                        if(evt.value){
                                            state.assistanceUnitExperience = []
                                        }
                                        setState({...state})
                                    }} checked={state.assistanceUnitExperience?.length === 0 ? true: false} text="No tengo experiencia en ninguna Unidad Asistencial" ></CheckBox>
                                </div>
                                <div className='d-flex my-3 px-3 px-md-5'>
                                    <div className='col'></div>
                                    <div className='col d-flex justify-content-around '>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                    </div>
                                    <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                    </div>
                                    </div>
                                </div>
                                { 
                                    assistanceUnit?.map((option, i)=>{

                                        if(i === 0){
                                            return;
                                        }
                                        return ( 
                                        
                                            <div key={i} className={`d-flex px-3 px-md-5 py-1 ${i%2 == 0  ? 'bg-white' : ''}`}>
                                                <div className='col'>
                                                    <div className='f-14 text-dark bold ps-4'>{option.description}</div>
                                                </div>
                                        
                                                <div className='col d-flex justify-content-evenly '>
                                                    
                                                <CheckBox text="" value="0" checked={assistanceUnitOptionChecked("0", option)}  onChange={(evt)=>{
                                                    
                                                        let unit = {
                                                        idUser:user.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    
                                                    if(evt.value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                            

                                                }}></CheckBox>
                                                <CheckBox text="" value="1" checked={assistanceUnitOptionChecked("1", option)}  onChange={(evt) => {
                                                        
                                                        let unit = {
                                                        idUser:user.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                                }} ></CheckBox>
                                                <CheckBox text="" value="2" checked={assistanceUnitOptionChecked("2", option)}  onChange={(evt) => {
                                                        
                                                        let unit = {
                                                        idUser:user.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                                }} ></CheckBox>

                                                <div className='d-none'>
                                                    <NuComboBox 
                                                    showkey={false} 
                                                    key={i} 
                                                    multiple={false} 
                                                    checks={yearsExperienceOptions} 
                                                    onChange={(evt)=>{

                                                    

                                                        let unit = {
                                                            idUser:user.id,
                                                            idAssistanceUnit:option.id,
                                                            yearsOfExperience:evt[0].key.id.toString(),

                                                        }
                                                        if(evt[0].value){
                                                            state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                            state.assistanceUnitExperience.push(unit);  
                                                        }else{
                                                            state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                            
                                                        }
                                                        
                                                    
                                                        setState({...state})
                                                            
                                                    
                                                    }}>
                                                    </NuComboBox>
                                                </div>
                                                </div>

                                            </div>
                                        
                                    
                                        
                                        )
                                    })
                                }
                                <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{                        
                                        updateAssistanceUnitExperience();                                                    
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Guardar</div>
                                    </div>
                                </div>
                            </Renderif>

                        </div>

                        <div className='bg-gray r-10 p-3 mt-3'>

                           
                            <div className='d-flex'>
                                <div className='f-16 bold mb-3'>En qué  ámbitos profesionales tienes experiencia</div>
                                <div className='col'></div>

                                <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                    if(state.editing_preferences === 'APE'){
                                        setState({...state, editing_preferences:null}) 
                                        return;
                                    }
                                    setState({...state, editing_preferences:'APE'})
                                }}>
                                    <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                                        <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                    </div>
                                </div>
                            </div>

                            <Renderif isTrue={state.editing_preferences === 'APE'}>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='col px-3 px-md-5'>
                                    <CheckBox onChange={(evt)=>{
                                    
                                        if(evt.value){
                                            state.professionalFieldExperience = []
                                        }
                                        setState({...state})
                                    }} checked={state.professionalFieldExperience?.length === 0 ? true: false} text="No tengo experiencia en ningún ámbito profesional"></CheckBox>
                                </div>
                                <div className='d-flex my-3 px-3 px-md-5'>
                                    <div className='col'></div>
                                    <div className='col d-flex justify-content-around '>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                    </div>
                                    <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                    </div>
                                    </div>
                                </div>
                        
                                { 
                                    professionalField.filter((item)=> item.id !== 7).map((option, i)=>{
                                            if(i === 0){
                                            return;
                                        }
                                        return ( 
                                        
                                            <div key={i} className={`d-flex px-3 px-md-5 py-1 ${i%2 == 0  ? 'bg-white' : ''}`}>
                                                <div className='col'>
                                                    <div className='f-14 text-dark bold ps-4'>{option.description}</div>
                                                </div>
                                        
                                                <div className='col d-flex justify-content-evenly '>
                                                <CheckBox text="" value="0" checked={optionChecked("0", option)}  onChange={(evt)=>{
                                                
                                                        let unit = {
                                                        idUser:user.id,
                                                        idProfessionalField:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    
                                                    if(evt.value){
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        state.professionalFieldExperience.push(unit);  
                                                    }else{
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        
                                                    }
                                            
                                                    setState({...state})
                                            

                                                }}></CheckBox>
                                                <CheckBox text="" value="1" checked={optionChecked("1", option)}  onChange={(evt) => {
                                                    
                                                        let unit = {
                                                        idUser:user.id,
                                                        idProfessionalField:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        state.professionalFieldExperience.push(unit);  
                                                    }else{
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        
                                                    }
                                                
                                                    setState({...state})
                                                }} ></CheckBox>
                                                <CheckBox text="" value="2" checked={optionChecked("2", option)}  onChange={(evt) => {
                                                    
                                                        let unit = {
                                                        idUser:user.id,
                                                        idProfessionalField:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        state.professionalFieldExperience.push(unit);  
                                                    }else{
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                                }} ></CheckBox>

                                                    <div className='d-none'>
                                                    <NuComboBox 
                                                        showkey={false} 
                                                        key={i} 
                                                        multiple={false} 
                                                        checks={yearsExperienceOptions} 
                                                        onChange={(value)=>{
                                                        

                                                            let unit = {
                                                                idUser:user.id,
                                                                idProfessionalField:option.id,
                                                                yearsOfExperience:value[0].key.id.toString(),

                                                            }
                                                            if(value[0].value){
                                                                state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                                state.professionalFieldExperience.push(unit);  
                                                            }else{
                                                                state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                            }
                                                            
                                                            
                                                                
                                                        
                                                        }}>
                                                    </NuComboBox>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                    
                                        
                                        )
                                    })
                                }
                                <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{                        
                                        updateProfessionalFieldExperience();                                                    
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Guardar</div>
                                    </div>
                                </div>
                            </Renderif>

                        </div>



                     

                    </div>
                   
                </div>



            </div>
 
            <div className='py-5 my-5'> </div>
    
        </div>

   
   
                      
     
    </div>
    </>
  )
}
