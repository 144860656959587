import React, { useContext, useState } from 'react'
import { CiLogout } from "react-icons/ci";
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/auth_context';
import Renderif from '../utils/renderif';
import { GoPeople } from "react-icons/go";
import { IoMdCheckboxOutline } from "react-icons/io";
export function ProfesionalSideMenu({page = '', show = false, onClose = () => {}}) {
    const {logout} = useContext(AuthContext)
    const navigate = useNavigate();

    const [state, setState] = useState({
        active:0
    })
   


  return (
         
        <div className={`nu-sidemenu ${show ? 'nu-sidemenu-show' : ''}`}>

            <Renderif isTrue={show}>
                <div className='d-flex d-sm-none mt-2 me-2'>
                    <div className='col'></div>
                    <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                        onClose()
                    }}>
                        <IoClose size={100} color="white"></IoClose>
                    </div>
                </div> 
            </Renderif>

            <div className='mt-0 mt-sm-4'>
                <img className='logo d-block mx-auto' src='/assets/nursea_icon.png'></img>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-5 cursor-pointer' onClick={()=>{
                setState({...state, active:0})
                navigate('/profesional')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="100%" src='/assets/category.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'dashboard' ? 'text-lightgreen':'text-white'}`}>Próximos <br></br> turnos</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer'onClick={()=>{
                 setState({...state, active:1})
                navigate('/profesional/turnos')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="110%" src='/assets/edit_calendar.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'turnos' ? 'text-lightgreen':'text-white'}`}>Mis turnos</div>
            </div>

           

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer' onClick={()=>{
                navigate('/profesional/perfil')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="100%" src='/assets/user-octagon.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'perfil' ? 'text-lightgreen':'text-white'}`}>Mi perfil</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-3 cursor-pointer' onClick={()=>{
                navigate('/profesional/valoraciones')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="100%" src='/assets/kid_star.svg'></img>
                
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'valoraciones' ? 'text-lightgreen':'text-white'}`}>Mis <br></br>Valoraciones</div>
            </div>
            
            <div className='col'></div>

            <div className='d-flex align-items-center ps-3 ps-md-4 my-3 cursor-pointer z-1' onClick={()=>{
                logout()
                navigate('/')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <CiLogout size={32} color='#FFF'></CiLogout>
                </div>
                <div className='f-16 text-white bold line-1 d-sm-none d-xl-block'>Salir</div>
            </div>

            <div className='nu-sidemenu-overlay'></div>
        </div>
  )
}
export function ClinicSideMenu({page = '', show = false, onClose = () =>{}}) {
    const navigate = useNavigate();
    const {logout} = useContext(AuthContext)

    const [state, setState] = useState({
        active:0
    })
    return (
           
        <div className={`nu-sidemenu ${show ? 'nu-sidemenu-show' : ''}`}>
             <Renderif isTrue={show}>
                <div className='d-flex d-sm-none mt-2 me-2'>
                    <div className='col'></div>
                    <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                        onClose()
                    }}>
                        <IoClose size={100} color="white"></IoClose>
                    </div>
                </div> 
            </Renderif>

            <div className='mt-4'>
                <img className='logo d-block mx-auto' src='/assets/nursea_icon.png'></img>
            </div>

            

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-5 cursor-pointer' onClick={()=>{
                setState({...state, active:0})
                navigate('/clinic')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="85%" src='/assets/edit_calendar.png'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'dashboard' ? 'text-lightgreen':'text-white'}`}>Turnos <br></br> publicados</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer'onClick={()=>{
                setState({...state, active:1})
                navigate('/clinic/solicitudes')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                   
                    <IoMdCheckboxOutline size={24} color="white"></IoMdCheckboxOutline>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'solicitudes' ? 'text-lightgreen':'text-white'}`}>Solicitudes</div>
            </div>

            
            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer'onClick={()=>{
                setState({...state, active:1})
                navigate('/clinic/resumen')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="90%" src='/assets/leaderboard_white.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'resumen' ? 'text-lightgreen':'text-white'}`}>Resumen de <br></br> actividad</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer' onClick={()=>{
                navigate('/clinic/valoraciones')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="90%" src='/assets/kid_star.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'valoraciones' ? 'text-lightgreen':'text-white'}`}>Valoraciones</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer'onClick={()=>{
                 setState({...state, active:1})
                navigate('/clinic/profesionales')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                   
                    <GoPeople size={18} color='white'></GoPeople>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'profesionales' ? 'text-lightgreen':'text-white'}`}>Profesionales</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer' onClick={()=>{
                navigate('/clinic/perfil')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="100%" src='/assets/user-octagon.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'perfil' ? 'text-lightgreen':'text-white'}`}>Mi perfil</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer' onClick={()=>{
                navigate('/clinic/facturacion')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="75%" src='/assets/euro_symbol_solid_white.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'facturacion' ? 'text-lightgreen':'text-white'}`}>Facturación</div>
            </div>

           
            
            <div className='col py-5'></div>

            <div className='d-flex align-items-center ps-4 my-3 cursor-pointer z-1' onClick={()=>{
                logout()
                navigate('/')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <CiLogout size={32} color='#FFF'></CiLogout>
                </div>
                <div className='f-16 text-white bold line-1 d-sm-none d-xl-block'>Salir</div>
            </div>

            <div className='nu-sidemenu-overlay'></div>
        </div>
    )
}
export function AdminSideMenu({page = '', show=false, onClose= () => {}}) {
    const navigate = useNavigate();
    const {logout} = useContext(AuthContext)
    const [state, setState] = useState({
        active:0
    })
    return (
           
        <div className={`nu-sidemenu ${show ? 'nu-sidemenu-show' : ''}`}>

        <Renderif isTrue={show}>
            <div className='d-flex d-sm-none mt-2 me-2'>
                <div className='col'></div>
                <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                    onClose()
                }}>
                    <IoClose size={100} color="white"></IoClose>
                </div>
            </div> 
        </Renderif>
            <div className='mt-4'>
                <img className='logo d-block mx-auto' src='/assets/nursea_icon.png'></img>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-5  cursor-pointer' onClick={()=>{
                setState({...state, active:0})
                navigate('/admin')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="90%" src='/assets/leaderboard_white.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'dashboard' ? 'text-lightgreen':'text-white'}`}>Monitor</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer' onClick={()=>{
                setState({...state, active:0})
                navigate('/admin/turnos')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="85%" src='/assets/edit_calendar_white.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'turnos' ? 'text-lightgreen':'text-white'}`}>Turnos</div>
            </div>
            
            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4 cursor-pointer'onClick={()=>{
                setState({...state, active:1})
                navigate('/admin/solicitudes')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <IoMdCheckboxOutline size={24} color="white"></IoMdCheckboxOutline>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'solicitudes' ? 'text-lightgreen':'text-white'}`}>Solicitudes</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4  cursor-pointer' onClick={()=>{
                setState({...state, active:0})
                navigate('/admin/profesionales')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="90%" src='/assets/user-octagon.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'profesionales' ? 'text-lightgreen':'text-white'}`}>Profesionales</div>
            </div>

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4  cursor-pointer' onClick={()=>{
                setState({...state, active:0})
                navigate('/admin/clinics')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="120%" src='/assets/local_hospital.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'organizaciones' ? 'text-lightgreen':'text-white'}`}>Organizaciones</div>
            </div>

            {/* <div className='d-flex align-items-center ps-4 mt-4 cursor-pointer' onClick={()=>{
                navigate('/admin/valoraciones')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="85%" src='/assets/kid_star.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-none d-xl-block ${page === 'valoraciones' ? 'text-lightgreen':'text-white'}`}>Valoraciones</div>
            </div> */}

            <div className='d-flex align-items-center ps-3 ps-md-4 mt-4  cursor-pointer' onClick={()=>{
                navigate('/admin/facturacion')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <img width="75%" src='/assets/euro_symbol_solid_white.svg'></img>
                </div>
                <div className={`f-14 bold line-1 d-sm-none d-xl-block ${page === 'facturacion' ? 'text-lightgreen':'text-white'}`}>Facturación</div>
            </div>
            

             <div className='col py-5'></div>

            <div className='d-flex align-items-center ps-4 my-3 cursor-pointer z-1' onClick={()=>{
                logout();
                navigate('/')
            }}>
                <div className='nu-icon nu-icon--mid me-2'>
                    <CiLogout size={32} color='#FFF'></CiLogout>
                </div>
                <div className='f-16 text-white bold line-1 d-sm-none d-xl-block'>Salir</div>
            </div>

     

            <div className='nu-sidemenu-overlay'></div>
        </div>
    )
}
