import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar, { NuCalendarMulti } from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import NuSelect from '../../components/nu_select';
import Renderif from '../../utils/renderif'
import { profile_data } from '../../utils/forms';
import { IoClose, IoCloseCircle } from 'react-icons/io5';
import { darkBlue } from '../../styles/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { Service } from '../../services/api';
import NuLoading from '../../components/nu_loading';
import { NotificationContext } from '../../context/notification_context';
import { alphaOrder, DayMonth, dM } from '../../utils';
import { GlobalContext } from '../../context/global_context';
import { AuthContext } from '../../context/auth_context';
import { BsThreeDotsVertical } from 'react-icons/bs';
import CheckBox from '../../components/nu_checkbox';
import { AdminContext } from '../../context/admin_context';
export default function PublicarTurnoClinicaView() {
    const navigate = useNavigate();
    const location = useLocation();
  
    let _turno = location.state?.turno === undefined ? null : location.state.turno;
    console.log(_turno)

    let _profesional = location.state?.profesional === undefined ? null : location.state.profesional;

    let _origin = location.state?.origin === undefined ? null : location.state.origin;
    if(_origin !== null){
        if(_origin === 'nueva_acogida'){
            console.log(_turno)
            _turno.needsReception = ''
            _turno.type = '0'
            _turno.remuneration = ''
            _turno.timeOfDay = ''
            _turno.initialHour = ''
            _turno.finalHour = ''
        }
    }
   


    const {notify} = useContext(NotificationContext);
    const {
        center,
        assistanceUnit,
        specialization,
        professionalField,
        centerType,
        getCenterType,
        getCenter,
        loadCenters
        
    } = useContext(AdminContext);
    const {user, user_data} = useContext(AuthContext)
    const [state, setState] = useState({
        idOrganization:_turno !== null ? _turno.idOrganization : null,
        show_menu:false,
        matches:0,
        errors:[],
        turnoType:'1',
        profesion:'',
        published:false,
        publishTo:_profesional !== null ? "2":"",
        profesionals:0,
        myProfesionals:[],
        selectedProfesionals:[],
        profesionalsWithExperience:[],
        allExperience:[],
        optionPublishTo: _profesional !== null ? "2":"0",
        radioButtonReception: '1',
        radioButtonMyTeam: '1',
        selectExperienceProfs: '',
    

        needsReception:'0',
        // requireModulo:'',

        dates:[],
        timeOfDay:'',
        initialHour:'',
        finalHour:'',
        remuneration:'',
        professionalFieldExperience:'0',
        assistanceUnitExperience:'0',
        education:'',
        valoracion:'0',
        experience:'0',
        
        center:[],
        centerType:[],
        assistanceUnit:[],
        specialization:[],
        professionalField:[],

        centerSelected:'',
        centerTypeSelected:'',
        assistanceUnitSelected:'',
        specializationSelected:'',
        professionalFieldSelected:'',

        assistanceUnitEducation:'0',
        professionalFieldEducation:'0',

        shiftDescription: '',

        loading:false,

        profesional_data:null

    

    })
    

    useEffect(()=>{

        const init = async() => {
            setState({...state, loading:true})
            loadCenters()

            // let query = `user/getMyProfessionals?page=1&pageCount=99`
            // let _profesionals = await Service('GET', query)
            // console.log(_profesional)
            // let _professionalsWithExperience = _profesionals.data?.list?.filter(item => _profesionals.data?.experience?.some(obj => obj.idProfessional === item.id))
            // let _allExperience = _profesionals.data?.experience

            if(_turno){

                let myProfesionals = await Service('GET', `user/getMyProfessionals/${_turno.idOrganization}`)
                let _professionalsWithExperience = myProfesionals.data?.list?.filter(item => myProfesionals.data?.experience?.some(obj => obj.idProfessional === item.id))
                let _allExperience = myProfesionals.data?.experience
                state.myProfesionals = myProfesionals.data.list
                state.profesionals = [myProfesionals.data.total]
                state.profesionalsWithExperience = _professionalsWithExperience
                state.allExperience = _allExperience

                let _optionPublishTo = "0"
                let _radioButtonMyTeam = "1";

                if(_turno.onlyMyTeam === true || _turno.onlyFavorites === true){
                    _optionPublishTo = "3"

                    if(_turno.onlyFavorites === true){
                        _radioButtonMyTeam = "2"
                    }
                  

                }else{
                    if(_turno.publicateTo !== null){
                        _optionPublishTo = "2"
                    }
                }

                
                


                if(_origin !== 'nueva_acogida'){
              
                    let date = new Date(_turno.date);
                    let gmt = date.toString().split(' ')
                    let zone = gmt[5].split('-')[1]
                    if(zone === undefined){
                    zone = gmt[5].split('+')[1]
                    }
                    let _turno_date = new Date(Date.parse(date.toUTCString() + '-' + zone))
                
                    state.dates = [{
                        date:_turno_date
                    }]
              
                   // state.publishTo = _turno.onlyFavorites === false ? _turno.publicateTo === null ? '0': _turno.onlyMyTeam === false && "2":'1';
                   state.publishTo = _turno.onlyFavorites === false ? _turno.onlyMyTeam === false && _turno.publicateTo !== null ? '2' : _turno.onlyMyTeam === true ? '0' : '0' :'1';
                   // state.optionPublishTo = _turno.onlyFavorites === false ? _turno.publicateTo === null ? '0': "":'3';
                   state.optionPublishTo = _turno.onlyFavorites === false ? _turno.onlyMyTeam === false && _turno.publicateTo !== null ? '0' : _turno.onlyMyTeam === true ? '3' : '0' :'3';
                   state.radioButtonMyTeam = _turno.onlyFavorites === true ? '2' : '1';
                   state.radioButtonReception = _turno.needsReception === "0" ? "1" : "2"
                   state.selectedProfesionals=_turno.publicateTo !== null ?  JSON.parse(_turno.publicateTo) : []
                   state.optionPublishTo = state.publishTo === '2' && state.optionPublishTo === '0' ? '' : state.optionPublishTo

                }else{
                    _optionPublishTo = "2"
                    if(_profesional !== null){
                        let _profesional_data = await Service('GET', `nurseData/getByIdUser/${_profesional?.id}`)
                        console.log(_profesional_data)
                        state.profesional_data = _profesional_data.data;
                    }
                    state.selectedProfesionals=_profesional !== null ? [_profesional.id] : []
                }
                console.log(_optionPublishTo)
                setState({...state, 
                    // profesionals:_profesionals.data.total,
                    // myProfesionals:_profesionals.data.list,
                    // profesionalsWithExperience:_professionalsWithExperience,
                    // allExperience: _allExperience,
                    centerSelected:_turno.idCenter,
                    centerTypeSelected:_turno.idCenterType?.toString(),
                    turnoType:_turno.type,
                    profesion:_turno.profession,
                    professionalFieldSelected:_turno.idProfessionalField,
                    assistanceUnitSelected:_turno.idAssistanceUnit?.toString(),
                    remuneration:_turno.remuneration,
                    initialHour:_turno.initialHour,
                    finalHour:_turno.finalHour,
                    timeOfDay:_turno.timeOfDay,
                    valoracion:_turno.appraisal,
                    experience:_turno.experience,
                    professionalFieldEducation:_turno.professionalFieldEducation === true ? '1':'0',
                    needsReception:_turno.needsReception,
                    specializationSelected:_turno.idSpecialization,
                    professionalFieldSelected:_turno.idProfessionalField?.toString(),
                    assistanceUnitEducation:_turno.assistanceUnitEducation === true ? '1':'0',
                    // publishTo:_turno.onlyFavorites === false ? _turno.publicateTo === null ? '0': "2":'1',
                    //selectedProfesionals:_turno.publicateTo !== null ?  JSON.parse(_turno.publicateTo) : [],
                    shiftDescription:_turno.description === null ? '' : _turno.description,
                    loading:false,
                    optionPublishTo:_optionPublishTo,
                    radioButtonMyTeam:_radioButtonMyTeam
               
                   

                })
            }else{
                setState({...state, loading:false, 
                    // profesionals:[_profesionals.data.total],
                     myProfesionals:[], 
                     selectedProfesionals:_profesional !== null ? [_profesional.id] : [], 
                    //  profesionalsWithExperience:_professionalsWithExperience, 
                    //  allExperience: _allExperience
                    })
            }
        }
        init();
        return () =>{}
    },[])

    useEffect(()=>{

        const init = async () => {

            if(state.publishTo !== '0'){
                return;
            }
            
            let body = {
               
                idCenter:parseInt(state.centerSelected),
                idCenterType: parseInt(state.centerTypeSelected),
                profession: state.profesion,
                type: state.turnoType,
                assistanceUnitEducation:state.assistanceUnitEducation,
                professionalFieldEducation: state.professionalFieldEducation,
                idAssistanceUnit: state.assistanceUnitSelected === '' ? null : parseInt(state.assistanceUnitSelected),
                idProfessionalField: parseInt(state.professionalFieldSelected),
                idSpecialization: state.specializationSelected === '' ? 1 : parseInt(state.specializationSelected),
                needsReception: state.needsReception !== '' ? state.needsReception : '0',
                appraisal: state.valoracion,
                experience:state.experience,
                remuneration: parseFloat(state.remuneration),
                professionalFieldExperience: state.professionalFieldExperience,
                assistanceUnitExperience: state.assistanceUnitExperience !== '' ? state.assistanceUnitExperience : null ,
            }
            let _mathes = await Service('POST', 'shift/professionalMatches', body);
            
            if(_mathes.status === 201){
                setState({...state, matches:_mathes?.data?.count})
            }
        }
        init()

        return () => {}

        
    },[
        state.profesion, 
        state.specializationSelected,
        state.professionalFieldSelected,
        state.centerTypeSelected,
        state.needsReception,
        state.turnoType,
        state.assistanceUnitSelected,
        state.experience,
        state.valoracion,
        state.professionalFieldExperience,
        state.assistanceUnitExperience,
        state.professionalFieldEducation,
        state.assistanceUnitEducation,
        state.centerSelected,
        
    
        
    ])


    const rederTurnoType = () => {
        if(state.turnoType === '') return '';
        let _turnoType = state.turnoType == '0' ? 'Acogida' : 'Cobertura'   
        return _turnoType;
    }
    const renderAssistanceUnit = () => {
        if(state.assistanceUnitSelected === '') return '';
        let _assistanceUnit = assistanceUnit.filter((item)=>item.id === parseInt(state.assistanceUnitSelected))[0]?.description 
        return _assistanceUnit;
    }
    const renderProfesion = () => {
        if(state.profesion === '') return '';
        let _profesion = state.profesion == '0' ? 'Enfermeria' : 'TCAE'   
        return _profesion;
    }
    const renderDays = () => {
        if(state.dates.length === 0) return '';
        let _days = '';

        for (let i = 0; i < state.dates.length; i++) {
            
            if(i == state.dates.length - 1){
                _days = _days + dM(state.dates[i].date) + ' '
            }else{
            _days = _days + dM(state.dates[i].date) + ', '
            }
            
        }
        
        
        return _days;
    }
    const validator = () => {
        state.errors = {}
        
        if(state.experience === ''){
            state.errors.experience = 'Este campo es obligatorio'
        }

        if(state.centerTypeSelected === '2'){
            if(state.assistanceUnitExperience === ''){
                state.errors.assistanceUnitExperience = 'Este campo es obligatorio'
            }
        }

        if(state.valoracion === ''){
            state.errors.valoracion = 'Este campo es obligatorio'
        }
        if(state.professionalFieldExperience === ''){
            state.errors.professionalFieldExperience = 'Este campo es obligatorio'
        }

        // if(state.profesion === '0'){
        //     if(state.education === ''){
        //         state.errors.education = 'Este campo es obligatorio'
        //     }
        // }
        
        if(state.remuneration === ''){
            state.errors.remuneration = 'Este campo es obligatorio'
        }
        if(state.centerSelected === ''){
            state.errors.centerSelected = 'Este campo es obligatorio'
        }
        if(state.centerTypeSelected === ''){
            state.errors.centerTypeSelected = 'Este campo es obligatorio'
        }
        if(state.turnoType === ''){
            state.errors.turnoType = 'Este campo es obligatorio'
        }
        if(state.profesion === ''){
            state.errors.profesion = 'Este campo es obligatorio'
        }
        if(state.professionalFieldSelected === ''){
            state.errors.professionalFieldSelected = 'Este campo es obligatorio'
        }
        if(state.dates.length === 0){
            state.errors.dates = 'Este campo es obligatorio'
        }
        if(state.initialHour === ''){
            state.errors.initialHour = 'Este campo es obligatorio'
        }
        if(state.finalHour === ''){
            state.errors.finalHour = 'Este campo es obligatorio'
        }
        if(state.timeOfDay === ''){
            state.errors.timeOfDay = 'Este campo es obligatorio'
        }

        if(state.profesion === '0'){
            if(state.specializationSelected === ''){
                state.errors.specializationSelected = 'Este campo es obligatorio'
            } 
        }
        // if(state.profesion === '0'){
        //     if(state.requireModulo === ''){
        //         state.errors.requireModulo = 'Este campo es obligatorio'
        //     } 
        // }
        if(state.centerTypeSelected === '2'){
            if(state.assistanceUnitSelected === ''){
                state.errors.assistanceUnitSelected = 'Este campo es obligatorio'
            } 
        }
        if(state.turnoType === '1'){
            if(state.needsReception === ''){
                state.errors.needsReception = 'Este campo es obligatorio'
            } 
        }

    

  

        setState({...state})
        if(Object.keys(state.errors).length > 0){
           
            return false;
        }
       return true;
        

    }
    const onCreateTurno = async() => {

        
        

        // // console.log(subDays(1, new Date(state.dates[0].date.setHours('18','00','00'))).getTime())
        //     let a = new Date(state.dates[0].date)
        //     let b = addDays(1, a)
        //     var utcDate = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), 0,0,0) / 1000

  



        if(!validator()){
      
            return;
        }
    
        setState({...state, loading:true})

        // Publicado a todos
        console.log(state.optionPublishTo)
        if (state.optionPublishTo === '0') {
            state.selectedProfesionals = []
        }
        
  
        
        let body = {
            status: "0",
            onlyFavorites:state.publishTo === '1' ? true : false, 
            onlyMyTeam:state.optionPublishTo === '3' ? true : false, 
            //Propiedad status es un enum, solo acepta los valores "0", "1" y "2"
            //"0" - Sin asignar
            //"1" - Asignado
            //"2" - Completado
            idUser: null,
            idOrganization: state.idOrganization,
            idCenter: parseInt(state.centerSelected), // El centro debe pertenecer a la Organización
            idCenterType: parseInt(state.centerTypeSelected),
            profession: state.profesion,
            //Propiedad profession es un enum, solo acepta los valores "0" y "1"
            //"0" - Nurse
            //"1" - TCAE
            type: state.turnoType,
            //Propiedad type es un enum, solo acepta los valores "0" y "1"
            //"0" - Acogida
            //"1" - Cobertura
            date: null, //Formato UNIX
            weekendShift: true,
            timeOfDay: state.timeOfDay,
            //Propiedad timeOfDay es un enum, solo acepta los valores "0", "1", "2" y "3"
            //"0" - Mañana
            //"1" - Día 12h
            //"2" - Tarde
            //"3" - Noche
            initialHour: state.initialHour,
            finalHour: state.finalHour,
            assistanceUnitEducation:state.assistanceUnitEducation,
            professionalFieldEducation: state.professionalFieldEducation,
            idAssistanceUnit: state.assistanceUnitSelected === '' ? null : parseInt(state.assistanceUnitSelected),
            idProfessionalField: parseInt(state.professionalFieldSelected),
            idSpecialization: state.specializationSelected === '' ? 1 : parseInt(state.specializationSelected),
            needsReception: state.needsReception !== '' ? state.needsReception : '0', //Necesita haber superado la acogida? si/no
            appraisal: state.valoracion,
            experience:state.experience,
            //Valoración del candidato
            //Propiedad appraisal es un enum, solo acepta los valores "0", "2", "3" y "4"
            //"0" - Cualquier valoración
            //"2" - Mínimo 2 estrellas
            //"3" - Mínimo 3 estrellas
            //"4" - Mínimo 4 estrellas
            remuneration: parseFloat(state.remuneration),
            professionalFieldExperience: state.professionalFieldExperience, //Opcional
            //Propiedad professionalFieldExperience es un enum, solo acepta los valores "0", "1", "2" y "3"
            //"0" - Sin experiencia
            //"1" - 1-5 años
            //"2" - 6-10 años
            //"3" - > 10 años
            assistanceUnitExperience: state.assistanceUnitExperience !== '' ? state.assistanceUnitExperience : null , //Opcional
            //Propiedad assistanceUnitExperience es un enum, solo acepta los valores "0", "1" y "2"
            //"0" - Sin experiencia
            //"1" - 1-12 meses
            //"2" - > 1 año
            education: state.education !== '' ? state.education : null,   //Opcional, solo aplica para turno tipo Nurse
            //Propiedad education es un enum, solo acepta los valores "0" y "1", al ser opcional acepta null para el caso en el que no aplique
            //"0" - Postgrado o Máster relacionado con UCI
            //"1" - Postgrado o Máster relacionado en Cardiología
            description: state.shiftDescription !== '' ? state.shiftDescription : null,
            // publicateTo: state.selectedProfesionals.length === 0 ? null : JSON.stringify(state.selectedProfesionals)
            publicateTo: state.selectedProfesionals.length !== 0 ? JSON.stringify(state.selectedProfesionals) : state.selectExperienceProfs !== '' ? JSON.stringify([]) : null
        }
      
      
        let _createTurno
        for (let i = 0; i < state.dates.length; i++) {
         
            let b = new Date(state.dates[i].date)

            var utcDate = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), 0,0,0)
            body.date = utcDate

            if(_turno && _origin !== 'nueva_acogida'){
                body.id = _turno.id
                _createTurno = await Service('PUT', 'shift/update', body);
        
            }else{
                _createTurno = await Service('POST', 'shift/create', body);
            }
          
           
      
            if(!_createTurno.status === 201 || _createTurno.status === undefined){
                setState({...state, loading:false});
                notify({title:'Error', message:_createTurno.response?.data?.error, type:'error'})
                return;
            }
            
        }
        state.published = true;
        cleanUp();
        // setState({...state, published:true})
       
    }
    const cleanUp = () => {
        setState({...state, 
                errors:[],
                turnoType:'',
                profesion:'',
                // published:false,
                needsReception:'',
                // requireModulo:'',
                dates:[],
                timeOfDay:'',
                initialHour:'',
                finalHour:'',
                remuneration:'',
                professionalFieldExperience:'0',
                assistanceUnitExperience:'0',
                education:'',
                valoracion:'',
                experience:'',
                center:[],
                centerType:[],
                assistanceUnit:[],
                specialization:[],
                professionalField:[],
                centerSelected:'',
                centerTypeSelected:'',
                assistanceUnitSelected:'',
                specializationSelected:'',
                professionalFieldSelected:'',
                assistanceUnitEducation:'0',
                professionalFieldEducation:'0',
                shiftDescription:''
         });
    }


  return (
    <>
   
     <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>



        <AdminSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page={'dashboard'}/>


   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto p-3'>

                {/* <div className='f-24  bold mt-4'>Publicar nuevo turno</div> */}

                <div className='d-flex flex-wrap mt-3'>

                    <div className='col-12 col-md-6'>
                        <div className='bg-skyblue p-4 r-10 me-md-2 h-100'>

                            <div className='d-flex align-items-center mt-2'>
                                <div className='col'>
                                    <div className='f-16 bold'>Centro</div>
                                </div>
                                <div className='col'>
                                    <NuSelect value={state.centerSelected}  onChange={ async (evt) => {
                                       
                                     
                                        state.selectedProfesionals = []
                                        let _center = center.find((item) => item.id === parseInt(evt.currentTarget.value))

                                        

                                        

                                        if(_center?.idCenterType !== null){

                                            state.assistanceUnitExperience = '0'
                                            state.centerTypeSelected = _center?.idCenterType.toString()
                                            state.assistanceUnitEducation = '0'

                                        }else{
                                            state.centerTypeSelected = ''
                                        }
                                        state.centerSelected = evt.currentTarget.value

                                        

                                        setState({...state})

                                        let myProfesionals = await Service('GET', `user/getMyProfessionals/${_center.idOrganization}`)
                                        let _professionalsWithExperience = myProfesionals.data?.list?.filter(item => myProfesionals.data?.experience?.some(obj => obj.idProfessional === item.id))
                                        let _allExperience = myProfesionals.data?.experience
                                        state.myProfesionals = myProfesionals.data.list
                                        state.profesionals = [myProfesionals.data.total]
                                        state.profesionalsWithExperience = _professionalsWithExperience
                                        state.allExperience = _allExperience
                                        state.idOrganization = _center.idOrganization
                                        setState({...state})

                                        

                                       
                                    }}>
                                        <option value=''>Seleccionar</option> 
                                        {center.map((item,i)=>{

                                      
                                    
                                            return (
                                                <option key={i} value={item.id}>{item.name}</option> 
                                            )
                                        })}

                                   
                               
                                    </NuSelect>
                                    <div className='text-danger f-12'>{state.errors?.centerSelected}</div>
                                </div>
                            </div>


                            <div className='d-flex align-items-center mt-2'>
                                <div className='col'>
                                    <div className='f-16 bold'>Tipo de centro</div>
                                </div>
                                <div className='col'>
                                    <NuSelect value={state.centerTypeSelected} onChange={(evt) => {
                                        console.log("WHATT")
                                        state.assistanceUnitExperience = '0'
                                        state.centerTypeSelected = evt.currentTarget.value
                                        state.assistanceUnitEducation = '0'

                                        setState({...state})
                                       
                                        // setState({...state, centerTypeSelected:evt.currentTarget.value})
                                        
                                    }}>
                                        <option value=''>Seleccionar</option> 
                                        {alphaOrder(centerType, 'description').map((item,i)=>{
                                                if(item.id==1) return;
                                           
                                                
                                                return (
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                          
                                        })}
                                    </NuSelect>
                                    <div className='text-danger f-12'>{state.errors?.centerTypeSelected}</div>
                                </div>
                            </div>
                            <Renderif isTrue={state.centerTypeSelected === '2'}>
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='col'>
                                        <div className='f-16 bold'>Unidad Asistencial </div>
                                    </div>
                                    <div className='col'>
                                        <NuSelect value={state.assistanceUnitSelected}  onChange={(evt) => {
                                        setState({...state, assistanceUnitSelected:evt.currentTarget.value})
                                       
                                    }}>

                                        <option value=''>Seleccionar</option> 
                                        { alphaOrder(assistanceUnit, 'description').map((item,i)=>{
                                                if(item.id === 1) {return}
                                                if(item.id === 14) {return}
                                                if(item.id === 21) {return}
                                                
                                                return (
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                          
                                        })}
                                            
                                        </NuSelect>
                                        <div className='text-danger f-12'>{state.errors?.assistanceUnitSelected}</div>
                                    </div>
                                </div>
                            </Renderif>
                            <div className='d-flex align-items-center mt-2'>
                                <div className='col'>
                                    <div className='f-16 bold'>Tipo de turno</div>
                                </div>
                                <div className='col'>
                                    <NuSelect disabled={true} value={state.turnoType} onChange={(evt)=>{
                                        setState({...state, turnoType:evt.currentTarget.value})
                                    }}>
                                        <option value="">Seleccionar</option>
                                        <option value="0">Acogida</option>
                                        <option value="1">Cobertura</option>

                                    </NuSelect>
                                    <div className='text-danger f-12'>{state.errors?.turnoType}</div>
                                </div>
                            </div>

                            {/* <Renderif isTrue={state.turnoType === '1'}>
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='col'>
                                        <div className='f-16 bold'>¿Necesita el profesional haber completado la acogida?</div>
                                    </div>
                                    <div className='col'>
                                        <NuSelect value={state.needsReception} onChange={(evt)=>{
                                            setState({...state, needsReception:evt.currentTarget.value})
                                        }}>
                                            <option value="">Seleccionar</option>
                                            <option value="0">No necesita una  acogida previa</option>

                                            <Renderif isTrue={state.centerTypeSelected !== '2'}>
                                                <option value="1">Necesita turno de acogida en el centro</option>
                                            </Renderif>

                                            <Renderif isTrue={state.centerTypeSelected === '2'}>
                                                <option value="2">Necesita turno de acogida en centro y la unidad asistencial solicitada</option>
                                            </Renderif>
                                        </NuSelect>
                                        <div className='text-danger f-12'>{state.errors?.needsReception}</div>
                                    </div>
                                </div>
                            </Renderif> */}

                            

                        </div>  
                    </div>

                    <div className='col-12 col-md-6'>
                        <div className='bg-skyblue p-4 r-10 ms-md-2 mt-3 mt-md-0 h-100'>

                            <div className='d-flex align-items-center mt-2'>
                                <div className='col'>
                                    <div className='f-16 bold'>Profesión</div>
                                </div>
                                <div className='col'>
                                    <NuSelect value={state.profesion}  onChange={(evt) => {
                                    
                                        state.specializationSelected = ''
                                        state.professionalFieldEducation = '0'
                                        state.profesion = evt.currentTarget.value
                                    
                                        setState({...state})
                                       
                                    }}>
                                      <option value=''>Seleccionar</option> 
                                      <option value='1'>TCAE</option> 
                                      <option value='0'>Enfermeria</option> 
                                      
                                    </NuSelect>
                                    <div className='text-danger f-12'>{state.errors?.profesion}</div>
                                </div>
                            </div>

                            {/* <Renderif isTrue={state.profesion === '0'}>
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='col'>
                                        <div className='f-14 bold'>Incluimos en tu selección estudiantes de enfermería que hayan completado:</div>
                                    </div>
                                    <div className='col'>
                                        <NuSelect value={state.requireModulo} onChange={(evt)=>{
                                            setState({...state, requireModulo:evt.currentTarget.value})
                                        }}>
                                            <option value="">Seleccionar</option>
                                            <option value="1">Todos los módulos del 2o curso</option>
                                            <option value="2">Todos los módulos del 3er curso</option>
                                            <option value="3">No quiero incluir a estudiantes</option>
                                     
                                
                                        </NuSelect>
                                        <div className='text-danger f-12'>{state.errors?.requireModulo}</div>
                                    </div>
                                </div>
                            </Renderif> */}

                            <Renderif isTrue={state.profesion === '0'}>
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='col'>
                                        <div className='f-16 bold'>Especialidades de Enfermería (EIR)</div>
                                    </div>
                                    <div className='col'>
                                        <NuSelect value={state.specializationSelected} onChange={(evt)=>{
                                            setState({...state, specializationSelected:evt.currentTarget.value})
                                            
                                        }}>
                                            <option value=''>Seleccionar</option> 
                                            
                                            {specialization.map((item,i)=>{
                                                    if(i==0) return (
                                                        <option key={i} value={item.id}>No requiere especialización</option> 
                                                    )

                                                    return (
                                                        <option key={i} value={item.id}>{item.description}</option> 
                                                    )
                                            
                                            })}
                                        </NuSelect>
                                        <div className='text-danger f-12'>{state.errors?.specializationSelected}</div>
                                    </div>
                                </div>
                                
                            </Renderif>

                            
                            <div className='d-flex align-items-center mt-2'>
                                <div className='col'>
                                    <div className='f-16 bold'>Ámbito profesional</div>
                                </div>
                                <div className='col'>
                                    <NuSelect value={state.professionalFieldSelected} onChange={(evt)=>{
                                             state.professionalFieldSelected = evt.currentTarget.value
                                             if(evt.currentTarget.value  === "" || evt.currentTarget.value === "1"){
                                                 state.professionalFieldExperience = "0"
                                                 state.professionalFieldEducation = "0"
                                             }
 
                                             // professionalFieldExperience
                                             setState({...state})
                                            
                                        }}>
                                             <option value=''>Seleccionar</option> 
                                             <option value="1">No aplica</option> 
                                            {alphaOrder(professionalField, 'description').filter((item) => item.id !== 7).map((item,i)=>{
                                                    if(item.id === 1) return;
                                                    return (
                                                        <option key={i} value={item.id}>{item.description}</option> 
                                                    )
                                            
                                            })}
                                    </NuSelect>
                                    <div className='text-danger f-12'>{state.errors?.professionalFieldSelected}</div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mt-2'>
                                <div className='col'>
                                    <div className='f-14 bold'>Remuneración por turno <br></br>(valor total bruto del turno)
</div>
                                </div>
                                <div className='col'>
                                <input placeholder='€ 0.00' value={state.remuneration} onChange={(evt)=>{
                                        state.remuneration = evt.currentTarget.value
                                        setState({...state})
                                    }} className='nu-input'></input>
                                     <div className='text-danger f-12'>{state.errors?.remuneration}</div>
                                </div>
                            </div>

                        </div> 
                    </div>
                </div>

                <div className='d-flex flex-wrap mt-lg-4'>

                    <div className='col-12 col-lg-4 d-flex flex-column justify-content-center p-4 p-lg-0'>
                        <div className='f-22 text-darkblue bold d-none d-lg-block'>¿Quieres cubrir 1 día o vas a <br></br> publicar el mismo turno en<br></br> varios días a la vez?</div>
                        <div className='f-22 text-darkblue bold d-block d-lg-none'>¿Quieres cubrir 1 día o vas a  publicar <br></br>el mismo turno en varios días a la vez?</div>
                        <div className='f-18 bold'>Selecciónalos en el calendario:</div>
                    </div>

                    <div className='col-12 col-lg-8'>
                        <div className='bg-skyblue p-4 r-10 ms-md-2 h-100'>
                            <div className='d-flex flex-wrap'>
                                <div className='col-12 col-md-6'>
                                    <div className='pe-5'>

                                        <Renderif isTrue={_turno === null}>
                                            
                                            <NuCalendarMulti dates={state.dates} onChange={(evt, dates)=>{
                                               
                                                state.dates = dates;
                                                setState({...state})
                                            }}></NuCalendarMulti>
                                        </Renderif>


                                        <Renderif isTrue={_turno !== null && _origin === 'nueva_acogida'}>
                                            
                                            <NuCalendarMulti dates={state.dates} onChange={(evt, dates)=>{
                                               
                                                state.dates = dates;
                                                setState({...state})
                                            }}></NuCalendarMulti>
                                        </Renderif>

                                       

                                        <Renderif isTrue={_turno !== null && _origin !== 'nueva_acogida'}>
                                      
                                            <NuCalendar value={_turno?.date} dates={state.dates} onChange={(evt)=>{
                                             
                                                state.dates = [{
                                                    date:evt
                                                }];
                                                setState({...state})
                                            }}></NuCalendar>
                                        </Renderif>

                                         <div className='text-danger f-12'>{state.errors?.dates}</div>
                                    </div>
                                
                                </div>
                                <div className='col-12 col-md-6 ps-3 ps-md-0 mt-3 mt-md-0'>

                                    <div className='d-flex align-items-center mt-2'>
                                        <div className='col'>
                                            <div className='f-16 bold'>Dias</div>
                                        </div>
                                        <div className='col'>
                                            <input type='number' disabled value={state.dates.length} className='nu-input'></input>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className='col'>
                                            <div className='f-16 bold'>Inicio de turno</div>
                                        </div>
                                        <div className='col'>
                                            <input type='time' value={state.initialHour} onChange={(evt)=>{
                                                state.initialHour = evt.currentTarget.value;
                                                setState({...state})
                                            }} className='nu-input'></input>
                                             <div className='text-danger f-12'>{state.errors?.initialHour}</div>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mt-2'>
                                        <div className='col'>
                                            <div className='f-16 bold'>Fin de turno</div>
                                        </div>
                                        <div className='col'>
                                            <input type='time' value={state.finalHour} onChange={(evt)=>{
                                                state.finalHour = evt.currentTarget.value;
                                                setState({...state})
                                            }} className='nu-input'></input>
                                             <div className='text-danger f-12'>{state.errors?.finalHour}</div>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mt-2'>
                                        <div className='col'>
                                            <div className='f-16 bold'>Horario del turno</div>
                                        </div>
                                        <div className='col'>
                                            <NuSelect value={state.timeOfDay} onChange={(evt)=>{
                                                setState({...state, timeOfDay:evt.currentTarget.value})
                                            }}>
                                                <option value="">Seleccionar</option>
                                                <option value="0">Mañana</option>
                                                <option value="2">Tarde</option>
                                                <option value="3">Noche</option>
                                                <option value="1">Día 12h</option>
                                                <option value="4">Guardia 24h</option>
                                            </NuSelect>
                                            <div className='text-danger f-12'>{state.errors?.timeOfDay}</div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>                  
                    </div>
                </div>

                <Renderif isTrue={_origin === 'nueva_acogida'}>
                    <div className='d-flex flex-wrap mt-3'>
                        <div className='col-12 col-md-6'>
                            <div className='bg-skyblue p-4 r-10 me-md-2 h-100'>

                                <div className='d-flex align-items-center'>
                                    <div className='f-18 bold me-3'>Publicar turno para:</div>
                                    <div>
                                        {state.selectedProfesionals.map((item, i)=>{
                                            let profesional = state.myProfesionals.find((el) => el.id === item)
                                            return (
                                                <div key={i} className='nu-tag nu-tag--blue cursor-pointer m-0' onClick={()=>{
                                                    // state.selectedProfesionals = state.selectedProfesionals.filter((id)=>id !== profesional.id)
                                                    // setState({...state})
                                                }}>
                                                    {profesional?.name} <span className='f-10 ms-1'>{profesional?.favorite === true ? "♥":""}</span>

                                                    
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='bg-skyblue p-4 r-10 ms-md-2 h-100'>
                                <div className='f-14 bold me-3'>Disponibilidad de este profesional:</div>
                                <div className='f-14 me-3'>{state.profesional_data?.nurseData?.availability}</div>
                            </div>
                        </div>
                    </div>
                </Renderif>
                <Renderif isTrue={_origin !== 'nueva_acogida'}>
                    <div className='d-flex flex-wrap bg-skyblue p-4 r-10 mt-lg-4'>

                        <div className='col-12 col-lg-4 d-flex align-items-center'>
                            <div className='col'>
                                <div className='f-16 bold'>Publicar turno para:</div>
                            </div>
                            <div className='col'>
                                <NuSelect value={state.optionPublishTo}  onChange={(evt) => {
                                    if (evt.currentTarget.value === '3') {
                                        state.selectedProfesionals = []
                                        state.myProfesionals?.map((item) => state.selectedProfesionals.push(parseInt(item.id)))
                                        setState({...state, needsReception: ''})
                                    }
                                    if (evt.currentTarget.value === '2') {
                                        state.selectedProfesionals = []
                                        state.publishTo = '2'
                                        setState({...state, needsReception: '', publishTo: '2', selectedProfesionals:[]})
                                    }
                                    if (evt.currentTarget.value !== '2') {
                                        state.publishTo = ''
                                        setState({...state, publishTo: '', selectedProfesionals:[]})
                                    }
                                    setState({...state, optionPublishTo:evt.currentTarget.value})
                                }}>
                                    
                                    <option value="">Seleccionar</option>
                                    <option value="0">Todos los profesionales</option>
                                    {/* <option value="1">Favoritos</option> */}
                                    <option value="3">Mi equipo</option>
                                    <option value="2">Profesional</option>
                        
                    
                        
                                </NuSelect>
                                {/* <div className='text-danger f-12'>{state.errors?.professionalFieldEducation}</div> */}
                            </div>
                        </div>
                        

                        {/* <Renderif isTrue={state.publishTo === '0' }>
                            <div className='col-12 col-lg-2 ps-2'>

                                <NuSelect value={state.publishTo}  onChange={(evt) => {

                                    // setState({...state, publishTo:evt.currentTarget.value, selectedProfesionals:[]})
                                    }}>

                                    <option value="0">Sin Acogida</option>
                                    <option value="1">Con Acogida</option>
                                



                                </NuSelect>
                            </div>
                        </Renderif> */}

                        <Renderif isTrue={state.optionPublishTo === '0' }>
                            <div className='col-12 col-lg-2 ps-2 mx-3'>
                                <div className='nu-input'>
                                    <CheckBox checked={state.radioButtonReception === '1'} text={"Sin acogida previa"} value={"0"} onChange={(evt)=>{
                                        setState({...state, needsReception: '0', radioButtonReception: '1'})
                                    }}/>
                                </div>
                            </div>
                            <div className='col-12 col-lg-2 ps-2'>
                                <div className='nu-input'>
                                    <CheckBox checked={state.radioButtonReception === '2'} text={"Con acogida previa"} value={"1"} onChange={(evt)=>{
                                        if (state.centerTypeSelected === "2") {
                                            setState({...state, needsReception: '2', radioButtonReception: '2'})
                                        } else {
                                            setState({...state, needsReception: '1', radioButtonReception: '2'})
                                        }
                                    }}/>
                                </div>
                            </div>
                        </Renderif>

                        <Renderif isTrue={state.optionPublishTo === '3'}>
                            {/* <div className='col-12 col-lg-4 d-flex align-items-center'></div>
                            <div className='col-12 col-lg-2 ps-2 py-3'></div> */}
                            <div className='col-12 col-lg-2 ps-2 py-3' style={{ marginInline: '3rem' }}>
                                <div className='nu-input'>
                                    <CheckBox checked={state.radioButtonMyTeam === '1'} text={"A todo el equipo"} value={"3"} onChange={(evt)=>{
                                        state.selectedProfesionals = []
                                        state.myProfesionals?.map((item) => state.selectedProfesionals.push(parseInt(item.id)))
                                        setState({...state, publishTo: '0', radioButtonMyTeam: '1'})
                                    }}/>
                                </div>
                            </div>
                            <div className='col-12 col-lg-2 ps-2 py-3' style={{ marginRight: '3rem' }}>
                                <div className='nu-input'>
                                    <CheckBox checked={state.radioButtonMyTeam === '2'} text={"A mis favoritos"} value={"1"} onChange={(evt)=>{
                                        state.selectedProfesionals = []
                                        state.myProfesionals.filter((item)=> item.favorite === true)?.map((item) => state.selectedProfesionals.push(parseInt(item.id)))
                                        setState({...state, publishTo: '1', radioButtonMyTeam: '2'})
                                    }}/>
                                </div>
                            </div>
                            <div className='col-12 col-lg-2 py-4'></div>
                            <div className='col-12 col-lg-2 py-4'>
                                <div className='f-14 bold'>Con experiencia en</div>
                            </div>
                            <div className='col-12 col-lg-3 py-3' style={{ marginRight: '3rem' }}>
                                <NuSelect value={state.selectExperienceProfs} onChange={(evt) => {
                                    if (evt.currentTarget.value === '') {
                                        if (state.radioButtonMyTeam === '1') {
                                            state.myProfesionals?.map((item) => state.selectedProfesionals.push(parseInt(item.id)))
                                        }
                                        if (state.radioButtonMyTeam === '2') {
                                            state.myProfesionals.filter((item)=> item.favorite === true)?.map((item) => state.selectedProfesionals.push(parseInt(item.id)))
                                        }
                                        setState({...state, selectExperienceProfs: ''})
                                    }
                                    if (evt.currentTarget.value === '0') {
                                        // setState({...state, selectedProfesionals: []})
                                        state.selectedProfesionals = []
                                        const profsFiltered = state.profesionalsWithExperience.filter(professional => state.allExperience.filter(item => item?.idAssistanceUnit?.toString() === state.assistanceUnitSelected).some(obj => obj.idProfessional === professional.id))
                                        if (state.radioButtonMyTeam === '1') {
                                            profsFiltered.map(prof => state.selectedProfesionals.push(parseInt(prof.id)))
                                        }
                                        if (state.radioButtonMyTeam === '2') {
                                            let favs = state.myProfesionals.filter((item) => item.favorite === true).map(item => item.id)
                                            const commonElements = [
                                                ...new Set(profsFiltered.filter(prof => favs.includes(prof.id)))
                                            ]
                                            commonElements.map(prof => state.selectedProfesionals.push(parseInt(prof.id)))
                                        }
                                        setState({...state, selectExperienceProfs: '0'})
                                    }
                                    if (evt.currentTarget.value === '1') {
                                        // setState({...state, selectedProfesionals: []})
                                        state.selectedProfesionals = []
                                        const profsFiltered = state.profesionalsWithExperience.filter(professional => state.allExperience.filter(item => item?.idProfessionalField?.toString() === state.professionalFieldSelected).some(obj => obj.idProfessional === professional.id))
                                        if (state.radioButtonMyTeam === '1') {
                                            profsFiltered.map(prof => state.selectedProfesionals.push(parseInt(prof.id)))
                                        }
                                        if (state.radioButtonMyTeam === '2') {
                                            let favs = state.myProfesionals.filter((item) => item.favorite === true).map(item => item.id)
                                            const commonElements = [
                                                ...new Set(profsFiltered.filter(prof => favs.includes(prof.id)))
                                            ]
                                            commonElements.map(prof => state.selectedProfesionals.push(parseInt(prof.id)))
                                        }
                                        setState({...state, selectExperienceProfs: '1'})
                                    }
                                    if (evt.currentTarget.value === '2') {
                                        // setState({...state, selectedProfesionals: []})
                                        state.selectedProfesionals = []
                                        let assistanceUnitFiltered = state.profesionalsWithExperience.filter(professional => state.allExperience.filter(item => item?.idAssistanceUnit?.toString() === state.assistanceUnitSelected).some(obj => obj.idProfessional === professional.id))
                                        let professionalFieldFiltered = state.profesionalsWithExperience.filter(professional => state.allExperience.filter(item => item?.idProfessionalField?.toString() === state.professionalFieldSelected).some(obj => obj.idProfessional === professional.id))
                                        assistanceUnitFiltered = assistanceUnitFiltered.map(item => item.id)
                                        professionalFieldFiltered = professionalFieldFiltered.map(item => item.id)
                                        // const commonProfs = assistanceUnitFiltered.filter(item => professionalFieldFiltered.includes(item))
                                        let commonProfs
                                        if (state.radioButtonMyTeam === '1') {
                                            commonProfs = [...new Set(
                                                assistanceUnitFiltered.filter(number => professionalFieldFiltered.includes(number))
                                                .concat(professionalFieldFiltered.filter(number => assistanceUnitFiltered.includes(number)))
                                              )]
                                        }
                                        if (state.radioButtonMyTeam === '2') {
                                            let favs = state.myProfesionals.filter((item) => item.favorite === true).map(item => item.id)
                                            commonProfs = [...new Set(
                                                assistanceUnitFiltered.filter(number => professionalFieldFiltered.includes(number))
                                                .concat(professionalFieldFiltered.filter(number => assistanceUnitFiltered.includes(number)))
                                            )]
                                            commonProfs = [
                                                ...new Set(commonProfs.filter(prof => favs.includes(prof)))
                                            ]
                                        }
                                        setState({...state, selectExperienceProfs: '2', selectedProfesionals: commonProfs})
                                    }
                                }}>
                                    <option value="">Sin Experiencia</option>
                                    <option value="0">Unidad Asistencial</option>
                                    <option value="1">Ámbito profesional</option>
                                    <option value="2">Unidad y ámbito</option>
                                </NuSelect>
                            </div>
                            {/* <div className='col-12 col-lg-3 ps-2 py-3'>
                                <NuSelect defaulValue="" onChange={(evt) => {
                                    if(state.selectedProfesionals.includes(parseInt(evt.currentTarget.value))){
                                        return;
                                    }
                                    state.selectedProfesionals.push(parseInt(evt.currentTarget.value))
                                    setState({...state, publishTo: '2'})
                                }}>
                                    <option value="">Profesional</option>
                                    {state.myProfesionals.map((item, i) => {
                                        return (
                                            <option key={i} value={item.id}>{item.name} {item.favorite === true ? "♥":""}</option>
                                        )
                                    })}
                                </NuSelect>
                            </div>
                    
                            <div className='col col-lg-8'></div>
                            <div className='col-12 col-lg-4 d-flex flex-wrap pt-1 ps-5'>
                                    {state.selectedProfesionals.map((item, i)=>{
                                        let profesional = state.myProfesionals.find((el) => el.id === item)
                                        return (
                                            <div key={i} className='nu-tag nu-tag--blue cursor-pointer' onClick={()=>{
                                                state.selectedProfesionals = state.selectedProfesionals.filter((id)=>id !== profesional.id)
                                                setState({...state})
                                            }}>
                                                {profesional?.name} <span className='f-10 ms-1'>{profesional?.favorite === true ? "♥":""}</span>

                                                
                                            </div>
                                        )
                                    })}
                            </div> */}
                        </Renderif>

                   


                        <Renderif isTrue={state.publishTo === "2"}>
                
                        <div className='col-12 col-lg-4  d-flex align-items-center mt-2 mt-lg-0'>
                            <div className='col ps-0 ps-lg-3'>
                                <div className='f-16 bold'>Profesional:</div>
                            </div>
                            <div className='col'>
                                <NuSelect defaulValue="" onChange={(evt) => {
                                    // setState({...state, publishTo:evt.currentTarget.value})
                                    if(state.selectedProfesionals.includes(parseInt(evt.currentTarget.value))){
                                        return;
                                    }
                                    state.selectedProfesionals.push(parseInt(evt.currentTarget.value))
                                    setState({...state})
                                }}>
                                    <option value="">Seleccionar</option>
                                    {state.myProfesionals.map((item, i) => {
                                        return (
                                            <option key={i} value={item.id}>{item.name} {item.favorite === true ? "♥":""}</option>
                                        )
                                    })}
                                    
                                    
                                
                        
                    
                        
                                </NuSelect>
                                {/* <div className='text-danger f-12'>{state.errors?.professionalFieldEducation}</div> */}
                            </div>
                        </div>

                        <div className='col col-lg-4'></div>
                            
                    
                        <div className='col col-lg-4'></div>
                        <div className='col-12 col-lg-4 d-flex flex-wrap pt-1 ps-3'>
                                {state.selectedProfesionals.map((item, i)=>{
                                    let profesional = state.myProfesionals.find((el) => el.id === item)
                                    return (
                                        <div key={i} className='nu-tag nu-tag--blue cursor-pointer' onClick={()=>{
                                            // state.selectedProfesionals = state.selectedProfesionals.filter((id)=>id !== profesional.id)
                                            // setState({...state})
                                        }}>
                                            {profesional?.name} <span className='f-10 ms-1'>{profesional?.favorite === true ? "♥":""}</span>

                                            
                                        </div>
                                    )
                                })}
                        </div>
                        </Renderif>

                    </div>
                </Renderif>

                <Renderif  isTrue={_origin !== 'nueva_acogida'}>
                <Renderif  isTrue={state.optionPublishTo === '0'}>
                    <div className='d-flex mt-4'>
                        <div className='col'>
                            <div className='bg-skyblue p-4 r-10 me-md-2 h-100'>
                                <div className='d-flex flex-wrap'>
                                    <div className='col-12 col-md-6'>

                                        <div className='me-md-2'>

                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='col'>
                                                    <div className='f-16 bold'>Años de experiencia <br></br> en la profesión</div>
                                                </div>
                                                <div className='col'>
                                                    <NuSelect value={state.experience} onChange={(evt) => {
                                                        setState({...state, experience:evt.currentTarget.value})
                                                    
                                                    }}>
                                                        <option value="">Seleccionar</option>
                                                        <option value="0">Sin experiencia</option>
                                                        <option value="1">1-5 años</option>
                                                        <option value="2">6-10 años</option>
                                                        <option value="3">{'> 10 años'}</option>
                                            
                                                    </NuSelect>
                                                    <div className='text-danger f-12'>{state.errors?.experience}</div>
                                                </div>
                                            </div>

                                            <Renderif isTrue={state.centerTypeSelected === '2'}>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='col'>
                                                        <div className='f-16 bold'>Experiencia en la <br></br> Unidad Asistencial</div>
                                                    </div>
                                                    <div className='col'>
                                                        <NuSelect value={state.assistanceUnitExperience} onChange={(evt) => {
                                                    
                                                            setState({...state, assistanceUnitExperience:evt.currentTarget.value})
                                                        }}>
                                                            <option value="">Seleccionar</option>
                                                            <option value="0">Sin experiencia</option>
                                                            <option value="1"> 1-12 meses</option>
                                                            <option value="2">{'> 1 año'}</option>
                                                
                                                        </NuSelect>
                                                        <div className='text-danger f-12'>{state.errors?.assistanceUnitExperience}</div>
                                                    </div>
                                                </div>
                                            </Renderif>

                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='col'>
                                                    <div className='f-16 bold'>Valoración del profesional</div>
                                                </div>
                                                <div className='col'>
                                                    <NuSelect value={state.valoracion} onChange={(evt) => {
                                                        setState({...state, valoracion:evt.currentTarget.value})
                                                        
                                                    }}>
                                                        <option value="">Seleccionar</option>
                                                        <option value="0">Cualquier valoración</option>
                                                        <option value="2">Mínimo 2 estrellas</option>
                                                        <option value="3">Mínimo 3 estrellas</option>
                                                        <option value="4">Mínimo 4 estrellas</option>
                                            
                                                    </NuSelect>
                                                    <div className='text-danger f-12'>{state.errors?.valoracion}</div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                    <div className='col-12 col-md-6'>

                                        <div className='ms-md-2'>

                                            <Renderif isTrue={state.professionalFieldSelected !== "1" && state.professionalFieldSelected !== ''}>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='col'>
                                                        <div className='f-16 bold'>Experiencia en el ámbito <br></br> profesional</div>
                                                    </div>
                                                    <div className='col'>
                                                        <NuSelect value={state.professionalFieldExperience} onChange={(evt) => {
                                                            setState({...state, professionalFieldExperience:evt.currentTarget.value})
                                                        }}>
                                                            <option value="">Seleccionar</option>
                                                            <option value="0">Sin experiencia</option>
                                                            <option value="1"> 1-12 meses</option>
                                                            <option value="2">{'> 1 año'}</option>
                                                
                                                        </NuSelect>
                                                        <div className='text-danger f-12'>{state.errors?.professionalFieldExperience}</div>
                                                    </div>
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={state.profesion === '0' && state.professionalFieldSelected !== '1' && state.professionalFieldSelected !== ''  }>
                                            
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='col'>
                                                        <div className='f-16 bold'>Formación en el ámbito profesional</div>
                                                    </div>
                                                    <div className='col'>
                                                        <NuSelect value={state.professionalFieldEducation}  onChange={(evt) => {
                                                            setState({...state, professionalFieldEducation:evt.currentTarget.value})
                                                        }}>
                                                            
                                                            <option value="0">No se requiere</option>
                                                            <option value="1">Se requiere</option>
                                                
                                            
                                                
                                                        </NuSelect>
                                                        {/* <div className='text-danger f-12'>{state.errors?.professionalFieldEducation}</div> */}
                                                    </div>
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={state.centerTypeSelected === '2' && state.profesion === '0'}>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='col'>
                                                        <div className='f-16 bold'>Formación en la unidad asistencial</div>
                                                    </div>
                                                    <div className='col'>
                                                        <NuSelect value={state.assistanceUnitEducation}  onChange={(evt) => {
                                                            setState({...state, assistanceUnitEducation:evt.currentTarget.value})
                                                        }}>
                                                        
                                                            <option value="0">No se requiere</option>
                                                            <option value="1">Se requiere</option>
                                                
                                            
                                                
                                                        </NuSelect>
                                                        {/* <div className='text-danger f-12'>{state.errors?.assistanceUnitEducation}</div> */}
                                                    </div>
                                                </div>
                                            </Renderif>

                                            
                                        

                                        </div>

                                        

                                    </div>
                                </div>
                                            
                            </div>
                        </div>
                    </div>
                </Renderif>
                </Renderif>

                <div className='d-flex flex-wrap mt-4'>
                    <div className='col-12 col-md-9'>
                        <div className='bg-skyblue shadow-sm p-4 r-10 me-md-2 h-100'>

                            <div className='f-18 bold text-darkblue'>Resumen previo</div>

                            <div className='d-flex mt-3'>
                                <div className='col'>
                                    {/* <div className='f-14 mt-1'><span className='bold'>Título del turno</span>: {renderTitle()} </div> */}
                                    <div className='f-14 mt-1'><span className='bold'>Centro</span>: {center.filter((item)=>item.id === parseInt(state.centerSelected))[0]?.name}</div>
                                    <div className='f-14 mt-1'><span className='bold'>Tipo de Centro</span>: { getCenterType(state.centerTypeSelected)}</div>
                                    <div className='f-14 mt-1'><span className='bold'>Tipo de turno</span>: {rederTurnoType()}</div>
                                 
                                </div>
                                <div className='col'>
                                    <Renderif isTrue={state.centerTypeSelected === '2'}>
                                        <div className='f-14 mt-1'><span className='bold'>Unidad asistencial</span>: {renderAssistanceUnit()}</div>
                                    </Renderif>
                                    <div className='f-14 mt-1'><span className='bold'>Profesión</span>: {renderProfesion()}</div>
                                    <div className='f-14 mt-1'><span className='bold'>Día</span>: {renderDays()}</div>
                                    {/* <div className='f-14 mt-1'><span className='bold'>Valoración</span>: {renderValoracion()}</div> */}
                         
                                </div>
                            </div>

                            
                        </div>
                        
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='bg-overlay  d-flex align-items-center justify-content-center ms-md-2 r-10 py-2 py-md-0 h-100'>
                          
                            <div className='f-18 text-white bold text-center'>
                                Tus criterios de <br></br> selección coinciden <br></br> con <span className='f-22 bold mx-1'>
                                    {/* Cantidad para todos */}
                                    <Renderif isTrue={state.optionPublishTo === "0" && _origin !== 'nueva_acogida'}>
                                        {state.matches}
                                    </Renderif>
    
                                    {/* Cantidad para un profesional */}
                                    <Renderif isTrue={state.publishTo === "2"}>
                                        {state.selectedProfesionals.length}
                                    </Renderif>
                                    {/* Cantidad para todos pero sin experiencia */}
                                    <Renderif isTrue={state.optionPublishTo === "3" && state.selectExperienceProfs === "" && state.radioButtonMyTeam === '1'}>
                                        {state.myProfesionals.length}
                                    </Renderif>
                                    {/* Cantidad para mis favoritos pero sin experiencia */}
                                    <Renderif isTrue={state.optionPublishTo === "3" && state.selectExperienceProfs === "" && state.radioButtonMyTeam === '2'}>
                                        {state.myProfesionals.filter((item)=> item.favorite === true).length}
                                    </Renderif>
                                    {/* Cantidad para cuando se requiere experiencia en algun de los filtros */}
                                    <Renderif isTrue={state.optionPublishTo === "3" && state.selectExperienceProfs !== ""}>
                                        {state.selectedProfesionals.length}
                                    </Renderif>
                                    
                                </span> perfiles
                            </div>

                          
                        </div>                  
                    </div>
                </div>

                <div className='f-18 bold text-darkblue mt-2 px-3 mt-4'>Descripción del turno: </div>
                <textarea rows={5} value={state.shiftDescription} className='nu-input mt-3 f-14' placeholder='Añade un comentario a tu turno' onChange={(evt)=>{
                    setState({...state, shiftDescription:evt.currentTarget.value})
                }}></textarea>

                <div className='d-flex my-5'>
                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                        // setState({...state, published:true})
                        // cleanUp()
                        onCreateTurno()
                    }}>
                        <div className='nu_btn-text f-18'>Publicar turno</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                </div>

            </div>

       
      
        </div>

        <Renderif isTrue={state.published}>

            <div className='nu-modal'>

                <div className='nu-modal-body'>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, published:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>
                    <div className='col d-flex flex-column justify-content-center align-items-center'>
                        <img width="70px" src='/assets/tick-circle.png'></img>
                        <div className='f-18 bold text-center'>¡Tu turno se ha publicado <br></br> exitosamente!</div>
                        <div className='nu_btn nu_btn--overlay mt-3' onClick={()=>{
                            navigate('/admin/turnos')
                        }}>
                            <div className='nu_btn-text f-18'>Ver turnos publicados</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>

                </div>
            </div>

        </Renderif>
   
   
  
     
    </div>
    </>
  )
}
