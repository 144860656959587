import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { MdOutlinePersonOutline, MdOutlinePersonOff } from "react-icons/md";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import NuSelect from '../../components/nu_select';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../services/api';
import { AdminContext } from '../../context/admin_context';
import Renderif from '../../utils/renderif';
export default function HomeAdminView() {
    const navigate = useNavigate();

    const {center} = useContext(AdminContext)

    const [state, setState] = useState({
        show_menu:false,
        turnos:[],
        tcae_count:0,
        nurse_count:0,
        blocked:0,
        validados:0,
        pendientes:0, 
        loading:true,
        sad:2

    })

    useEffect(()=>{
        const init = async() => {
            
            let _turnos = await Service('GET', 'shift/getAll')

            // let _turnos = await Service('GET', 'shift/getAll?idOrganization=38') 

            let _tcae_count = await Service('GET', `user/getProfessionals?page=1&pageCount=5&userType=3`) 
            let _nurse_count = await Service('GET', `user/getProfessionals?page=1&pageCount=5&userType=1`) 
            let _blocked = await Service('GET', `user/getProfessionals?page=1&pageCount=5&blocked=1`) 
            let _profesionals_validados = await Service('GET', `user/getProfessionals?page=1&pageCount=5&status=1`)
            let _profesionals_pendientes = await Service('GET', `user/getProfessionals?page=1&pageCount=5&status=0&documentationCompleted=1`)
              
            setState({
                ...state,
                tcae_count:_tcae_count.data.total,
                nurse_count:_nurse_count.data.total,
                blocked:_blocked.data.total,
                turnos:_turnos.data.list,
                validados:_profesionals_validados.data.total,
                pendientes:_profesionals_pendientes.data.total,
                loading:false
            })
        }
        init()
        
        setState({...state})
        return () => {}
    },[])

    const checkCompleted = (item) => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }
  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <AdminSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page={'dashboard'}/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                    <div className='f-22 text-white bold'>Hola, Admin</div>
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <div className='container-fluid container-lg mx-auto px-5 py-3'>

                <div className='d-flex'>
                    <div className='f-24 bold'>Turnos publicados</div>
                    <div className='col'></div>

                    {/* <div className='nu_btn nu_btn--overlay' onClick={()=>{
                     
                    }}>
                        <div className='nu_btn-text f-18'>Agregar sugerencias</div>
                        <div className='nu_btn-overlay'></div>
                    </div> */}
                    <div className='mx-2'></div>
                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                     navigate('publicar')
                    }}>
                        <div className='nu_btn-text f-18'>Publicar nuevo turno</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                </div>


    
           
                <div className='d-flex flex-wrap mt-4'>
                    <div className='col-12 col-md-4'>

                        <div className='d-flex justify-content-center align-items-center bg-skyblue shadow-sm r-10 py-4 me-md-4 mt-2 mt-md-0'>

                            
                            <div className='f-16 bold'>Asignados</div>
                     
                            <div className="pie no-round" style={{"--p":`${
                                (state.turnos?.filter((item)=>item.idUser !== null && !checkCompleted(item)).length * 100) / 
                                state.turnos.filter((item)=> !checkCompleted(item)).length
                            }`,"--c":'#00943A'}}>{state.turnos?.filter((item)=>item.idUser !== null && !checkCompleted(item)).length}</div>


                            
                            {/* <div className='nu-sumary'>
                                <div className='f-24 bold'>
                                    {state.turnos?.filter((item)=>item.idUser !== null && !checkCompleted(item)).length}
                                </div>
                            </div> */}
                        </div>
                        
                    </div>
                    <div className='col-12 col-md-4'>

                        <div className='d-flex justify-content-center align-items-center bg-skyblue shadow-sm r-10 py-4 ms-md-3 mt-2 mt-md-0'>
                            <div className='f-16 bold'>Pendientes de asignar</div>
                            <div className="pie no-round" style={{"--p":`${
                                (state.turnos.filter((item)=>item.idUser === null && !checkCompleted(item)).length * 100) /
                                state.turnos.filter((item)=> !checkCompleted(item)).length
                            }`,"--c":'#FBC029'}}>{state.turnos.filter((item)=>item.idUser === null && !checkCompleted(item)).length}</div>
                            {/* <div className='nu-sumary'>
                                <div className='f-24 bold'> {state.turnos.filter((item)=>item.idUser === null && !checkCompleted(item)).length}</div>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>

                        <div className='d-flex justify-content-center align-items-center bg-skyblue shadow-sm r-10 py-4 ms-md-3 mt-2 mt-md-0'>
                            <div className='f-16 bold'>Turnos Publicados</div>
                            <div className="pie no-round" style={{"--p":'100',"--c":'#30B7E7'}}>{state.turnos.filter((item)=> !checkCompleted(item)).length}</div>
                            {/* <div className='nu-sumary'>
                                <div className='f-24 bold'> {state.turnos.filter((item)=> !checkCompleted(item)).length}</div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='d-flex mt-5'>
                    <div className='f-24 bold'>Usuarios registrados</div>
                    <div className='col'></div>

                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                        navigate('/admin/profesionales')
                    }}>
                        <div className='nu_btn-text f-18'>Validar profesionales</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                  
                </div>

                <div className='d-flex flex-wrap mt-4 '>

                    <div className='col-12 col-md-4 pe-md-3'>

                        <div className='d-flex align-items-center shadow-sm r-10 py-4 h-100'>

                            <div className='col-md d-flex justify-content-md-end'>
                                <div className='bg-darkblue p-4 r-10 mx-3'>
                                    <div className='nu-icon nu-icon--big'>
                                        <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                    </div>
                                </div>
                            </div>
                            
                           <div className='col'>
                                <Renderif isTrue={state.loading}>
                                    <div className="spinner-border spinner--mid text-secondary"></div>
                                </Renderif>
                                <Renderif isTrue={!state.loading}>
                                    <div className='f-16 bold line-0'><span className="f-32">{center?.length}</span><br></br>Centros</div>
                                </Renderif>
                               
                           </div>
                        </div>
                        
                    </div>

                    <div className='col-12 col-md-4 px-md-3'>

                        <div className='d-flex align-items-center shadow-sm r-10 py-4 h-100'>
                            <div className='col-md d-flex justify-content-end'>
                                <div className='bg-darkblue p-4 r-10 mx-3'>
                                    <div className='nu-icon nu-icon--big'>
                                        <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                    </div>
                                </div>
                            </div>
                           <div className='col'>
                                <Renderif isTrue={state.loading}>
                                    <div className="spinner-border spinner--mid text-secondary"></div>
                                </Renderif>
                                <Renderif isTrue={!state.loading}>
                                    <div className='f-16 bold line-0'><span className="f-32">{state.nurse_count}</span><br></br>Enfermeras/os</div>
                                </Renderif>
                           </div>
                        </div>

                    </div>
                
                    <div className='col-12 col-md-4 ps-md3'>

                        <div className='d-flex align-items-center shadow-sm r-10 py-4 h-100'>
                            <div className='col-md d-flex justify-content-end'>
                                <div className='bg-darkblue p-4 r-10 mx-3'>
                                    <div className='nu-icon nu-icon--big'>
                                        <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                    </div>
                                </div>
                            </div>
                           <div className='col'>
                                <Renderif isTrue={state.loading}>
                                    <div className="spinner-border spinner--mid text-secondary"></div>
                                </Renderif>
                                <Renderif isTrue={!state.loading}>
                                    <div className='f-16 bold line-0'><span className="f-32">{state.tcae_count}</span><br></br>TCAE</div>
                                </Renderif>
                               
                           </div>
                        </div>

                    </div>
                </div>

                <div className='d-flex flex-wrap mt-2 mt-md-4  '>

                    <div className='col-12 col-md-4 pe-md-3'>

                        <div className='d-flex align-items-center shadow-sm r-10 py-4 h-100'>
                            <div className='col-md d-flex justify-content-end'>
                                <div className='bg-darkblue p-4 r-10 mx-3'>
                                    <div className='nu-icon nu-icon--big'>
                                        <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                    </div>
                                </div>
                            </div>
                           <div className='col'>
                                <Renderif isTrue={state.loading}>
                                    <div className="spinner-border spinner--mid text-secondary"></div>
                                </Renderif>
                                <Renderif isTrue={!state.loading}>
                                    <div className='f-16 bold line-0'><span className="f-32">{state.validados}</span><br></br>Profesionales <br></br> validados</div>
                                </Renderif>
                            

                           </div>
                        </div>
                        
                    </div>

                    <div className='col-12 col-md-4 px-md-3'>

                        <div className='d-flex align-items-center shadow-sm r-10 py-4 h-100'>
                            <div className='col-md d-flex justify-content-end'>
                                <div className='bg-darkblue p-4 r-10 mx-3'>
                                    <div className='nu-icon nu-icon--big'>
                                        <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                    </div>
                                </div>
                            </div>
                           <div className='col'>
                                <Renderif isTrue={state.loading}>
                                    <div className="spinner-border spinner--mid text-secondary"></div>
                                </Renderif>
                                <Renderif isTrue={!state.loading}>
                                    <div className='f-16 bold line-0'><span className="f-32">{state.pendientes}</span><br></br>Pendientes <br></br> de validar</div>
                                </Renderif>

                           </div>
                        </div>

                    </div>
                
                    <div className='col-12 col-md-4 px-md-3'>

                         <div className='d-flex align-items-center shadow-sm r-10 py-4 h-100'>

                            <div className='col-md d-flex justify-content-end'>
                            {/* <div className='bg-darkblue p-3 r-10 mx-3 d-flex flex-column justify-content-center align-items-center'> */}
                            <div className='bg-darkblue p-4 r-10 mx-3'>
                                <div className='nu-icon nu-icon--big'>
                                    <MdOutlinePersonOff size={32} color='white'></MdOutlinePersonOff>
                                </div>

                                <div className='d-flex'>
                                    {/* <div className='nu-icon'>
                                        <BsSearch size={10} color='white'></BsSearch>
                                    </div>
                                    <div className='f-12 bold text-white cursor-pointer' onClick={()=>{
                                        navigate('/admin/profesionales',{state:{blocked:true}})
                                    }}>Revisar</div> */}
                                </div>
                                </div>
                            </div>

                            <div className='col'>
                                <Renderif isTrue={state.loading}>
                                    <div className="spinner-border spinner--mid text-secondary"></div>
                                </Renderif>
                                <Renderif isTrue={!state.loading}>
                                    <div className='f-16 bold line-0'><span className="f-32">{state.blocked}</span><br></br>Bloqueados</div>
                                </Renderif>

                            </div>
                        </div>
                        
                    </div>
                </div>



               

             


            </div>

       
      
        </div>

   
   
  
     
    </div>
  )
}
