import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdDocument, IoMdEye, IoMdStar } from "react-icons/io";
import { MdArrowBack, MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { MdModeEdit } from "react-icons/md";
import { IoCloseCircleOutline,IoAddCircleOutline } from "react-icons/io5";

import { IoIosSearch } from "react-icons/io";
import { IoMdSave } from "react-icons/io";
import { AdminSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { darkBlue } from '../../styles/colors';
import Renderif from '../../utils/renderif';
import { AuthContext } from '../../context/auth_context';
import { useNavigate, useParams } from 'react-router-dom';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import { GlobalContext } from '../../context/global_context';
import NuLoading from '../../components/nu_loading';
import { useFilePicker } from 'use-file-picker';
import CheckBox from '../../components/nu_checkbox';
import NuComboBox from '../../components/nu_combobox';
import { AdminContext } from '../../context/admin_context';

import { BsCheckCircle, BsPersonSlash } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { FaFolder } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import { IoCloseCircle } from 'react-icons/io5';

import NuSelect from "../../components/nu_select";
import { documentsNurse, documentsTCAE, restrictionReasons, UTCDateParser } from "../../utils";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { UTCDateObj } from '../../utils';

export default function PerfilProfesionalAdminView() {
    const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
    const {idUser} = useParams()


    
    // var nurse_data_aux = user_data.idUserType === 1 ? user_data.nurseData : user_data.idUserType === 3 ?  user_data.tcaeData : {}

    const navigate = useNavigate()
    const {notify} = useContext(NotificationContext);
    const {specialization, centerType, professionalField, assistanceUnit, getRating, getAssistanceUnit, getProfessionalField, center, getCenter} = useContext(AdminContext)
    const { openFilePicker, filesContent, loading, plainFiles } = useFilePicker({
        accept: '*',
      });
    const [state, setState] = useState({
        user_data:null,
        assistanceUnit:[],
        professionalField:[],

        loading:false,
        editing_general:false,
        editing_preferences:null,
        user:{
            name:null,
            phone: null,
            nif: null,
            postal_code: null,
        },
        nurse_data:{},
        preferredCenter:[],
        preferredProfessionalField: [],
        preferredAssistanceUnit:[],
        educationAssistanceUnit:[],
        educationProfessionalField: [],
        assistanceUnitExperience: [],
        professionalFieldExperience:[],

        password:'',
        passwordConfirmed:'',
        errors:{},
        provincias: [
            {
                provincia: 'Andalucía',
                colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
            },
            {
                provincia: 'Aragón',
                colegios: ['Zaragoza', 'Teruel', 'Huesca']
            },
            {
                provincia: 'Principado de Asturias',
                colegios: ['Asturias']
            },
            {
                provincia: 'Illes Balears',
                colegios: ['Illes Balears']
            },
            {
                provincia: 'Canarias',
                colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
            },
            {
                provincia: 'Cantabria',
                colegios: ['Cantabria']
            },
            {
                provincia: 'Castilla y León',
                colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
            },
            {
                provincia: 'Castilla y la Mancha',
                colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
            },
            {
                provincia: 'Cataluña',
                colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
            },
            {
                provincia: 'Comunidad Valenciana',
                colegios: ['Alicante', 'Valencia', 'Castellón']
            },
            {
                provincia: 'Extremadura',
                colegios: ['Cáceres','Badajoz']
            },
            {
                provincia: 'Galicia',
                colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
            },
            {
                provincia: 'Murcia',
                colegios: ['Murcia']
            },
            {
                provincia: 'Madrid',
                colegios: ['Madrid']
            },
            {
                provincia: 'Navarra',
                colegios: ['Navarra']
            },
            {
                provincia: 'País Vasco',
                colegios: ['Bizcaia','Gipuzkoa','Araba']
            },
            {
                provincia: 'La Rioja',
                colegios: ['La Rioja']
            },
            {
                provincia: 'Ceuta',
                colegios: ['Ceuta']
            }
            ,
            {
                provincia: 'Melilla',
                colegios: ['Melilla']
            }
            
        ],
        _turnos: [],
        _turnos_show:5, 
        documents: [],
        _documents:[],
        filter_center:"",
        filter:"",
        reasons: [],
        modalToBlock: false,
        professionalToBlock: null,
        valoraciones: 0,
        date_filter:{
            init:'',
            end:''
        },
        restricciones: [],
        optionYears:'',
    })

    useEffect(()=>{
        const init = async () => {
            setState({...state, loading:true})
           

            let _assistanceUnit = await Service('GET', `assistanceUnit/getAll`);
            let _professionalField = await Service('GET', `professionalField/getAll`);
            let _userdata = await Service('GET', `user/getById/${idUser}`);
            console.log(_userdata)

            state.user = _userdata.data.user;
            if(state.user.idUserType === 1){
                state._documents = documentsNurse;
            }
            if(state.user.idUserType === 3){
                state._documents = documentsTCAE;
            }

            let _turnos = await Service('GET', `shift/getAll?idUser=${idUser}`) 
            state._turnos = _turnos.data.list?.sort((a,b) => b.date - a.date)
            for (let i = 0; i < state._documents.length; i++) {
                let _doc = await Service('GET', `document/view/${idUser}/${state._documents[i].type}`);
                if(_doc.status === 201){
                    let document = {
                        type:state._documents[i].type,
                        file:{path:_doc.data.url},
                        label:''
                    }
                    state.documents.push(document)
                
                }
            }

            // Info of rating user  
            let _valoraciones = await Service('GET', 'rating/getAll?idUser='+idUser);
            state.valoraciones = _valoraciones?.data?.list?.length
            // Info of rating user
            
            // Info of restrictions user  
            let _restricciones = await Service('GET', 'restriction/getAll');
            state.restricciones = _restricciones?.data?.list?.filter(item => item?.idUser.toString() === idUser)
            
            state.restricciones = state.restricciones?.map((item) => {
                return {
                    ...item,
                    center: getCenter(item?.idCenter)
                }
            })
            // Info of restrictions user

            var nurse_data_aux = _userdata.data.user.idUserType === 1 ? _userdata.data.user?.nurseData : _userdata.data.user.idUserType === 3 ?  _userdata.data.user?.tcaeData : {}
            
            let yearsText = ''
            if (nurse_data_aux?.yearsOfExperience === "0") {
                yearsText = "Sin experiencia"
            }
            if (nurse_data_aux?.yearsOfExperience === "1") {
                yearsText = "1-5 años"
            }
            if (nurse_data_aux?.yearsOfExperience === "2") {
                yearsText = "6-10 años"
            }
            if (nurse_data_aux?.yearsOfExperience === "3") {
                yearsText = ">10 años"
            }
        
            setState({...state, 
                loading:false, 

                user_data:_userdata.data.user,
                assistanceUnit:_assistanceUnit.data.list.filter((item) => item.id !== 16),
                professionalField:_professionalField.data.list,

                user:{
                    name:_userdata.data.user?.name,
                    phone: _userdata.data.user?.phone_number,
                    nif: _userdata.data.user?.cif,
                    postal_code: _userdata.data.user?.postal_code,
                },
                nurse_data:{
                    ...nurse_data_aux
                },
                preferredCenter:_userdata.data.user.preferredCenter,
                preferredProfessionalField: _userdata.data.user.preferredProfessionalField,
                preferredAssistanceUnit:_userdata.data.user.preferredAssistanceUnit,
                educationAssistanceUnit:_userdata.data.user.educationAssistanceUnit,
                educationProfessionalField: _userdata.data.user.educationProfessionalField,
                assistanceUnitExperience: _userdata.data.user.assistanceUnitExperience,
                professionalFieldExperience:_userdata.data.user.professionalFieldExperience,
                optionYears: yearsText,
            })
         
        };
        init();
        return () => {}
    },[])

    useEffect( ()=>{


        const init = async () => {

            if(filesContent.length > 0 && state.active_file_type !== null){     
           
                setState({...state, loading:true})
                await updateUserPictue(plainFiles[0])
                await setUserPicture();
         
                setState({...state, loading:false})
                
            }

        }
        init();
        

        return () => {}

     
    },[filesContent])

    const updateNurseData = async () => {
        let body = state.nurse_data;
        let _update = await Service('PUT', 'nurseData/update', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();
    }
    const updateTcaeData = async () => {
        let body = state.nurse_data;
   
        let _update = await Service('PUT', 'tcaeData/update', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        // getUserDateNurse();
        
    }
    const updatePreferredCenter =  async () => {
        let body = {
            preferredCenterList:[]
        }
        state.preferredCenter.map((center, i)=>{
            body.preferredCenterList.push({
                idUser:center.idUser,
                idCenterType:center.idCenterType
            })
        })
        let _update = await Service('POST', 'preferredCenter/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updatePreferredProfessionalField =  async () => {
        let body = {
            preferredProfessionalFieldList:[]
        }
        state.preferredProfessionalField.map((item, i)=>{
           
            body.preferredProfessionalFieldList.push({
                idUser:item.idUser,
                idProfessionalField:item.idProfessionalField
            })
        })
        let _update = await Service('POST', 'preferredProfessionalField/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updatePreferredAssistanceUnit=  async () => {
        let body = {
            preferredAssistanceUnitList:[]
        }
        state.preferredAssistanceUnit.map((item, i)=>{
           
            body.preferredAssistanceUnitList.push({
                idUser:item.idUser,
                idAssistanceUnit:item.idAssistanceUnit
            })
        })
        let _update = await Service('POST', 'preferredAssistanceUnit/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateEducationAssistanceUnit=  async () => {
        let body = {
            educationAssistanceUnitList:[]
        }
        state.educationAssistanceUnit.map((item, i)=>{
           
            body.educationAssistanceUnitList.push({
                idUser:item.idUser,
                idAssistanceUnit:item.idAssistanceUnit
            })
        })
        let _update = await Service('POST', 'educationAssistanceUnit/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateEducationProfessionalField=  async () => {
        let body = {
            educationProfessionalFieldList:[]
        }
        state.educationProfessionalField.map((item, i)=>{
           
            body.educationProfessionalFieldList.push({
                idUser:item.idUser,
                idProfessionalField:item.idProfessionalField
            })
        })
        let _update = await Service('POST', 'educationProfessionalField/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateAssistanceUnitExperience=  async () => {
        let body = {
            assistanceUnitExperienceList:[]
        }
        state.assistanceUnitExperience.map((item, i)=>{
           
            body.assistanceUnitExperienceList.push({
                idUser:item.idUser,
                idAssistanceUnit:item.idAssistanceUnit,
                yearsOfExperience:item.yearsOfExperience
            })
        })
        let _update = await Service('POST', 'assistanceUnitExperience/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }
    const updateProfessionalFieldExperience = async () => {
        let body = {
            professionalFieldExperienceList:[]
        }
        state.professionalFieldExperience.map((item, i)=>{
         
           
            body.professionalFieldExperienceList.push({
                idUser:item.idUser,
                idProfessionalField:item.idProfessionalField,
                yearsOfExperience:item.yearsOfExperience
            })
        })
        let _update = await Service('POST', 'professionalFieldExperience/create', body);
        if(_update.status === 201){
            notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
        }else{
            notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
        }
        getUserDateNurse();

    }



    const nurse_data = [
        {
            title:'Años de experiencia que tienes en la profesión',
            tags:[]
        },
        {
            title:'Especialización EIR',
            tags:[]
        },
        {
            title:'Centros donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Ámbitos profesionales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Unidades Asistenciales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Dispones de formación tipo master o postgrado relacionados con las siguientes unidades asistenciales',
            tags:[]
        },
        {
            title:'Dispones de formación tipo master o postgrado relacionados con los siguientes ámbitos profesionales',
            tags:[]
        },
        // {
        //     title:'En qué Unidades Asistenciales tienes experiencia',
        //     tags:[]
        // },
        // {
        //     title:'En qué  ámbitos profesionales tienes experiencia',
        //     tags:[]
        // }
    
    ]
    const tcae_data = [
        {
            title:'Años de experiencia que tienes en la profesión',
            tags:[]
        },
        {
            title:'',
            tags:[]
        },

        {
            title:'Centros donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Ámbitos profesionales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'Unidades Asistenciales donde tienes interés en realizar turnos',
            tags:[]
        },
        {
            title:'',
            tags:[]
        },
        {
            title:'',
            tags:[]
        },
        // {
        //     title:'En qué Unidades Asistenciales tienes experiencia',
        //     tags:[]
        // },
        // {
        //     title:'En qué  ámbitos profesionales tienes experiencia',
        //     tags:[]
        // }
    
    ]
    const profile_data = () => {
        if(state.user_data?.idUserType === 1){
            return nurse_data
        }
        if(state.user_data?.idUserType === 3){
            return tcae_data
        }
    }
    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$/;
        // var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const validator = () => {

        state.errors = {}

        if(!checkPassword(state.passwordConfirmed)){
            state.errors.password = 'Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)'
        }
        
        // if(state.passwordConfirmed !== state.password){
        //     state.errors.confirmPassword = 'Las contraseñas no coinciden'
        // }
       
        
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onUpdateProfile = async () => {
        
        let body = state.user;
      
        let update = await Service('PUT', 'user/updateProfessional', body );
        if(state.user_data?.idUserType === 1){   
            let _update = await Service('PUT', 'nurseData/update', state.nurse_data );
            
        }
   
        if(state.user_data?.idUserType === 3){   
            let _update = await Service('PUT', 'tcaeData/update', state.nurse_data );
       
        }
   
        if(update.status === 201){
            notify({title:"Información", message:'Tu datos han sido actualizados', type:'success'})
        }else{
            notify({title:"Información", message:'No ha sido posible actualizar tus datos.', type:'error'})
        }
        
        if(state.passwordConfirmed !== ''){

            body = {
                password:state.password,
                new_password:state.passwordConfirmed
            }

            if(!validator()){
        
                return;
            }
            let update_password = await Service('PUT', 'user/updatePassword', body );
        
            if(update_password.status === 201){
                notify({title:"Contraseña", message:'Tu contraseña ha sido actualizada', type:'success'})
            }else{
                notify({title:"Contraseña", message:'No ha sido posible cambiar tu contraseña', type:'error'})
            }
            setState({
                ...state, 
                password:'',
                passwordConfirmed:''
            })
            

        }
        getUserDateNurse()
    
    }
    const assistanceUnitOptionChecked = (value, option) =>{

        let _aux = state.assistanceUnitExperience.filter((item)=>{
            if(item.idAssistanceUnit === option.id && item.yearsOfExperience === value){
                return true;
            }
            return false;
        })

        if(_aux.length > 0){
            return true
        }
        return false

    }
    const optionChecked = (value, option) =>{

        let _aux = state.professionalFieldExperience.filter((item)=>{
            if(item.idProfessionalField === option.id && item.yearsOfExperience === value){
                return true;
            }
            return false;
        })

        if(_aux.length > 0){
            return true
        }
        return false

    }

    const yearsExperienceOptions = [
        {
            id:0,
            desctiprion:'No tengo experiencia'
        },
        {
            id:1,
            desctiprion:'Menos de un año'
        },
        {
            id:2,
            desctiprion:'Mas de 1 año'
        },

    ]

    const getDocument = (type) => {
        let _doc = state.documents.filter((item)=>item.type === type);
        if(_doc.length === 0){
            return  {
                file: {path:"Sin documento"}
            }
        }
        return _doc[0];
    }

    const exportToExcel = (data, filename) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        
        saveAs(blob, `${filename}.xlsx`);
    };

    const onDownloadShift = async () => {
        const shiftToSend = filterDates()
        .filter((item) => state.filter === "" ? true : item.status === state.filter )
        .filter((item) => state.filter_center === "" ? true : item?.center?.id === parseInt(state.filter_center) )
        .map((item) => {
            return {
            "Status": item?.status === "1" ? "Asignado" : "Completado",
            "Día": UTCDateParser(new Date(item?.date).toUTCString(), true),
            "Horario": `${item?.initialHour} - ${item?.finalHour}`,
            "Tipo de Turno": `Turno de ${item?.type === '0' ? 'Acogida' : 'Cobertura'}`,
            "Centro": item?.center?.name,
            "Unidad Asistencial": getAssistanceUnit(item?.idAssistanceUnit) || "",
            "Ambito Profesional": getProfessionalField(item?.idProfessionalField),
            }
        })

        exportToExcel(shiftToSend, `turnos-${state.user_data?.name}`);
    }

    const onBlockProfesional = async  () => {
        if(!state.reasons.length) {
            notify({title:'¡Error!', message:'Por favor selecciona al menos un motivo.', type:'error'})
            return
        }
        
        const reasonsBlock = state.reasons.sort().map((item) => restrictionReasons[item])
        let body = {
            blocked_reason: JSON.stringify(reasonsBlock)
        }
        
        setState({...state, loading:true})
        let _block = await Service ('PUT', `user/block/${state.professionalToBlock}`, body)
        console.log(_block)
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional bloqueado', type:'success'})
            setTimeout(() => {
                navigate(0)
            }, 1800)
        }else{
            notify({title:'Erorr!', message:'No se ha podiado bloquear este profesional.', type:'error'})
        }
        setState({...state, loading:false, reasons:[], modalToBlock:false, professionalToBlock:null})
    }

    const onUnblockProfesional = async  (idUser) => {
        setState({...state, loading:true})
        let body = {
            idUser
        }
        let _block = await Service ('PUT', `user/unblock/${idUser}`)
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional desbloqueado', type:'success'})
            setTimeout(() => {
                navigate(0)
            }, 1800)
        }else{
            notify({title:'Erorr!', message:'No se ha podiado desbloquear este profesional.', type:'error'})
        }
        setState({...state, loading:false})
    }

    const onValidate = async () => {
        let _validate = await Service('PUT', `user/validate/${idUser}`);
        if(_validate.status === 201){
            notify({title:'Listo!', message:'Usuario validado exitosamente.', type:'success'})
        }else{
            notify({title:'Erorr!', message:'No se ha podido validar el usuario.', type:'error'})
        }
    }

    const filterDates = () => {
        let _turnos = state._turnos
        if(state.date_filter.init !== '' && state.date_filter.end !== ''){
            let _init = new Date(state.date_filter.init).getTime();
            let _end = new Date(state.date_filter.end).getTime();

            _turnos = _turnos.filter((item) => {
                let _turno = new Date(item.date).getTime();
                return _turno >= _init && _turno <= _end;
            });
        }
        return _turnos
    }


  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>

    <div className='container-fluid overflow-hidden d-flex h-100'>


        <AdminSideMenu page='profesionales'/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, Admin</div>
                        {/* <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>{getRating().rate} ( {getRating().valoraciones} reseñas)</div>

                        </div> */}
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container p-3'>

                {/* <div className='f-22 bold mb-3'>Mi Perfil</div> */}

                <div></div>


                <div className='d-flex'>

                    <div className='d-flex flex-column align-items-center pe-3 mt-4 pt-3'>
                        {/* <div className='avatar avatar--big'></div> */}
                        {/* <div className='avatar avatar--big' style={{backgroundImage: `url(${user?.picture})`}}></div> */}
                        {/* <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            openFilePicker()
                        }}>
                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                        </div> */}
                    </div>

                    <div className='col'>

                        <div className='d-flex align-items-center cursor-pointer mb-3' onClick={()=>{
                            navigate(-1)
                        }}>
                                <div className='nu-icon nu-icon--mid'>
                                    <MdArrowBack size={17} color={darkBlue}></MdArrowBack>
                                </div>
                                <div className='f-14 bold text-darkblue'>Atrás</div>
                        </div>

                        <div className="d-flex align-items-center py-2">
                            <div className='f-18 bolder'>Perfil</div>
                            <div className='col'></div>
                            <div className='d-flex me-2'>
                                {!state.user_data?.blocked ? (
                                    <div className='nu_btn nu_btn--danger  w-100' onClick={()=>{
                                        setState({...state, modalToBlock:true, professionalToBlock: state.user_data?.id})
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <BsPersonSlash size={20}></BsPersonSlash>
                                        </div>
                                        <div className='nu_btn-text f-14'>Bloquear profesional</div>
                                    </div>
                                ) : (
                                    <div className='nu_btn nu_btn--block  w-100' onClick={()=>{
                                        onUnblockProfesional(state.user_data?.id)
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <BsPersonSlash size={20}></BsPersonSlash>
                                        </div>
                                        <div className='nu_btn-text f-14'>Desbloquear profesional</div>
                                    </div>
                                )}
                            </div>

                            <Renderif isTrue={state.user_data?.status === "0"}>
                                <div className='d-flex'>
                                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                        onValidate()
                                    }}>
                                        <div className='nu_btn-text f-14 px-1 bold'>Validar profesional</div>
                                        <div className='nu_btn-overlay'></div>
                                    </div>
                                </div>
                            </Renderif>
                        </div>

                        <div className='bg-gray r-10 p-3'>
                            <div className="d-flex align-items-center bg-light r-10 p-3 mt-2">
                                <div className="d-none d-lg-flex flex-column align-items-center me-3">
                                    <div className="avatar avatar--mid"></div>
                                </div>

                                <div className="col">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-6 col-lg-2">
                                            <div className="bold">{state.user_data?.name} {state.user_data?.lastName}</div>
                                        </div>
                                        <div className="col-6 col-lg-2"><span className="bold">DNI:</span>{state.user_data?.cif}</div>
                                        <div className="col-6 col-lg-4"><span className="bold">Colegio:</span> {state.user_data?.idUserType === 1 ? state.user_data?.nurseData?.college : state.user_data?.tcaeData?.college ? state.user_data?.tcaeData?.college : "Ninguno"}</div>
                                        <div className="col-6 col-lg-4"><span className="bold">No. de Colegiado:</span> {state.user_data?.idUserType === 1 ? state.user_data?.nurseData?.collegiateNumber : state.user_data?.tcaeData?.collegiateNumber ? state.user_data?.tcaeData?.collegiateNumber : "Ninguno" }</div>
                                    </div>
                                    <div className="d-flex flex-wrap mt-2">
                                        <div className="col-6 col-lg-2">
                                            <div>{state.user_data?.idUserType === 1 ? "Enfermera/o" : "TCAE"}</div>
                                        </div>
                                        <div className="col-6 col-lg-2">
                                            <div className="d-flex align-items-center">
                                                <div className="nu-icon nu-icon--mid">
                                                <IoMdStar color="#FFB054" size={24}></IoMdStar>
                                                </div>
                                                <div className="f-15">{state.user_data?.stars?.toFixed(2) || 0} &nbsp;</div> <span className="f-14">{`(${state.valoraciones} reseñas)`}</span>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-4"><span className="bold">Años de Experiencia: &nbsp;</span>{state.optionYears }</div>
                                        <div className="col-6 col-lg-3"><span className="bold">Turnos completados: &nbsp;</span>{state._turnos.length}</div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex'>
                                {/* <div className='f-22 bold mb-3'>Profesional</div>
                                <div className='col'></div> */}

                                {/* <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                    setState({...state, editing_general:!state.editing_general, errors: {}})
                                }}>
                                    <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                    <div className='nu-icon nu-icon--mid' onClick={()=>{

                                    }}>
                                        <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                    </div>
                                </div> */}
                            </div>

                            {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                <div className='col'>
                                    <div className='f-16 pe-5 bold'>Nombre Completo</div>
                                </div>
                                <div className='col'>

                                    
                                    
                                    <input disabled={!state.editing_general} defaultValue={state.user_data?.name} onChange={(evt)=>{
                                        state.user.name = evt.currentTarget.value;

                                    }} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>


                                </div>


                                <div className='col'>
                                    <div className='f-16 ps-5 bold'>{state.user_data?.idUserType === 1 ? 'Colegio' : 'Comunidad Autónoma'}</div>
                                </div>
                                <div className='col'>
                                    <>{state.nurse_data?.college}</>
                                    <Renderif isTrue={state.user_data?.idUserType === 1}>


                                        <select disabled={!state.editing_general} value={state.nurse_data?.college} onChange={(evt)=>{

                                                state.nurse_data.college = evt.currentTarget.value;
                                                setState({...state})
                                        
                                            
                                                
                                            }}  className={`nu-select nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>
                                            <option value="">Seleccionar</option>
                                            {
                                                state.provincias.filter((item) => item.provincia === state.nurse_data?.collegeProvince)[0]?.colegios.map((item, i)=>{
                                                    console.log(item)
                                                    return(
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                    
                                        </select>

                                    </Renderif>
                               
                                
                        
                                    <Renderif isTrue={state.user_data?.idUserType !== 1}>
                                
                                        <select disabled={!state.editing_general} value={state.nurse_data?.province} onChange={(evt)=>{

                                                state.nurse_data.province = evt.currentTarget.value;
                                                setState({...state})
                                        
                                            
                                                
                                            }}  className={`nu-select nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>
                                            <option value="">Seleccionar</option>
                                            {
                                                state.provincias.map((item, i)=>{
                                                    return(
                                                        <option key={i} value={item.provincia}>{item.provincia}</option>
                                                    )
                                                })
                                            }
                                    
                                        </select>
                                    </Renderif>
                               
                                
                                </div>

                                
                            </div> */}

                            {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                <div className='col'>
                                    <div className='f-16 pe-5 bold'>Teléfono</div>
                                </div>
                                <div className='col'>
                                    <input disabled={!state.editing_general} defaultValue={state.user_data?.phone_number} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.user.phone = evt.currentTarget.value;
                                        
                                    }}></input>
                                </div>
                                <div className='col'>
                                    <div className='f-16 ps-5 bold'>DNI</div>
                                </div>
                                <div className='col'>
                                <input disabled={!state.editing_general} defaultValue={state.user_data?.cif} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.user.nif = evt.currentTarget.value;
                                        
                                    }}></input>
                                </div>
                            </div> */}

                            {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                <div className='col'>
                                    <div className='f-16 pe-5 bold'>Correo electrónico</div>
                                </div>
                                <div className='col'>
                                    <input disabled defaultValue={state.user_data?.email}  className={`nu-input--light p-2 nu-input--disabled`}></input>
                                </div>
                                <div className='col'>
                                    <div className='f-16 ps-5 bold'>Contraseña actual:</div>
                                    <div className='f-16 ps-5 bold'>Documentos</div>
                                </div>
                                <div className='col'>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        navigate('/admin/documentacion/'+idUser)
                                    }}>
                                        <div className='nu-icon'>
                                            <IoMdDocument size={14}></IoMdDocument>
            
                                        </div>
                                        <div className='nu_btn-text f-12'>Ver documentos</div>
                                    </div>
                                <input disabled={!state.editing_general} value={state.password} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                     state.password = evt.currentTarget.value;
                                     setState({...state})
                                }}></input>
                                </div>
                            </div> */}

                            {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                <div className='col'>
                                    <div className='f-16 pe-5 bold'>Código postal</div>
                                </div>
                                <div className='col'>
                                    <input disabled={!state.editing_general} defaultValue={state.user_data?.postal_code}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.user.postal_code = evt.currentTarget.value;
                                        
                                    }}></input>
                                </div>
                                <div className='col'>
                                    <div className='f-16 ps-5 bold'>Nueva contraseña</div>
                                </div>
                                <div className='col'>
                                    <input disabled={!state.editing_general}  value={state.passwordConfirmed}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                     state.passwordConfirmed = evt.currentTarget.value;
                                     setState({...state})
                                }}></input>
                                  <div className='text-danger f-12'>{state.errors?.password}</div>
                                </div>
                                
                            </div> */}

                            {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                <div className='col'>
                                    <Renderif isTrue={state.user_data?.idUserType === 1}>
                                        <div className='f-16 pe-5 bold'>No. de Colegiado/a</div>
                                    </Renderif>
                                </div>
                                <div className='col'>
                                    <Renderif isTrue={state.user_data?.idUserType === 1}>
                                    <input disabled={!state.editing_general} defaultValue={state.nurse_data?.collegiateNumber}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </Renderif>
                                </div>
                                
                                <div className='col'>
                                    <div className='f-16 ps-5 bold'>Documentos</div>
                                </div>
                                <div className='col'>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        navigate('/profesional/documentacion')
                                    }}>
                                        <div className='nu-icon'>
                                            <IoMdDocument size={14}></IoMdDocument>
            
                                        </div>
                                        <div className='nu_btn-text f-12'>Ver documentos</div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <Renderif isTrue={state.user_data?.idUserType === 1}>
                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Comunidad Autónoma</div>
                                    </div>
                                    <div className='col'>

                                    
                                        <select disabled={!state.editing_general} defaultValue={state.user_data?.idUserType === 1 ? state.nurse_data?.collegeProvince : state.nurse_data?.province } onChange={(evt)=>{

                                        
                                        if(state.user_data?.idUserType === 1){
                                            state.nurse_data.collegeProvince = evt.currentTarget.value;
                                            // state.nurse_data.province = evt.currentTarget.value; 
                                        }else{
                                            state.nurse_data.province = evt.currentTarget.value; 
                                        }
                                        setState({...state})
                                        
                                            


                                        }}  className={`nu-select nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>
                                        <option value="" disabled>Seleccionar</option>
                                        {
                                            state.provincias.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.provincia}>{item.provincia}</option>
                                                )
                                            })
                                        }
                                
                                    </select>
                                    </div>


                                    <div className='col'></div>
                                    <div className='col'></div>

                                    
                                </div>
                            </Renderif> */}

                            <Renderif isTrue={state.editing_general}>
                                <div className='d-flex mt-5'>
                                    
                                    <div className='col'></div>

                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        // setState({...state, show_turno_detail:!state.show_turno_detail})
                                        onUpdateProfile();
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Guardar</div>
                                    </div>
                                </div>
                            </Renderif>

                        </div>
                        <div className='py-1'/>
                        <div className='bg-gray r-10 p-3'>
                            <div className="d-flex flex-wrap mt-2">
                                <div className="col-6 col-lg-4">
                                    <span className="bold">Correo electrónico: </span>{state.user_data?.email}
                                </div>
                                <div className="col-6 col-lg-2" style={{ marginRight: '2em' }}>
                                    <div className="d-flex align-items-center">
                                        <span className="bold">Teléfono: &nbsp;</span>{state.user_data?.phone_number}
                                    </div>
                                </div>
                                <div className="col-6 col-lg-2"><span className="bold">Código postal: </span>{state.user_data?.postal_code}</div>
                                <div className="col-6 col-lg-3"><span className="bold">Comunidad Autónoma: </span>{state.user_data?.idUserType === 1 ? state.user_data?.nurseData?.collegeProvince : state.user_data?.tcaeData?.province }</div>
                            </div>
                        </div>
                        
                        <div className='py-1'/>
                        <div className='bg-gray r-10 p-3'>
                            <div className="d-flex flex-wrap">
                                {state.restricciones.length ? (
                                    <>
                                        <span className="f-12">Este profesional ha sido bloqueado en el Centro:&nbsp;</span>
                                        {state.restricciones?.map((item, i) => {
                                            return (
                                                <span className="f-12 bold">
                                                    {i === 0 ? item?.center?.name : 
                                                        i !== state.restricciones?.length-1 ? 
                                                        `, ${item?.center?.name}` : 
                                                        <>&nbsp;y {item?.center?.name}.</>
                                                    }
                                                </span>
                                            )
                                        })}
                                    </>
                                ):(
                                    <span className="f-12 bold">Este Profesional no ha sido bloqueado en ningún Centro.</span>
                                )}
                            </div>
                        </div>

                        <div className="d-flex align-items-center mt-4">
                            <div className="f-18 bolder">Turnos</div>
                        </div>

                        <div className="d-flex align-items-end w-100">
                            <div>
                                <div className="d-flex flex-wrap">
                                    <div className="col-6 col-lg-4 px-1">
                                        <div className="f-15">Mostrar</div>
                                    </div>
                                    <div className="col-6 col-lg-5 px-1">
                                        <NuSelect className="nu-select--sm" value={state._turnos_show} onChange={(evt)=>{
                                            setState({...state, _turnos_show:evt.currentTarget.value })
                                        }}>
                                            <option>5</option>
                                            <option>10</option>
                                            <option>20</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </NuSelect>
                                    </div>
                                    <div className="col-6 col-lg-3 px-1"><span className="f-15">turnos</span></div>
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col-1.5 ms-1">
                                <div className="f-12 bold text-gray">Filtrar por</div>
                                <input type='date' onChange={(evt)=>{
                                    state.date_filter.init = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'></input>
                            </div>
                            <div className="ms-1 py-2">
                                <span className="f-15 bold px-1 ">a</span>
                            </div>
                            <div className="col-1.5 ms-1">
                                <input type='date'  onChange={(evt)=>{
                                    state.date_filter.end = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'></input>
                            </div>
                            <div className="col-2 ms-1">
                                <div className="f-12 bold text-gray">Centro</div>
                                <NuSelect className={'nu-select'} value={state.filter_center} onChange={(evt)=>{
                                    setState({...state, filter_center:evt.currentTarget.value })
                                }}>
                                <option value="">Todos</option>
                                {center?.map((item,i)=>{
                                    return (
                                        <option key={i} value={item.id}>{item.name}</option> 
                                    )
                                })}
                                </NuSelect>
                            </div>
                            <div className="col-2 ms-1">
                                <div className="f-12 bold text-gray">Status</div>
                                <NuSelect className="nu-select" value={state.filter} onChange={(evt)=>{
                                    setState({...state, filter:evt.currentTarget.value })
                                }}>
                                    <option value="">Todos</option>
                                    <option value="1">Asignados</option>
                                    <option value="2">Completados</option>
                                </NuSelect>
                            </div>    
                            <div className="col-1 mx-3">
                                    <div className='nu_btn nu_btn--primary' onClick={onDownloadShift}>
                                    <div className='nu_btn-text f-14 px-1 bold'>Descargar</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-2 w-100 ">
                                <div className="bg-gray w-100 r-10 mt-2 overflow-auto hide-scroll">
                                    <table className="w-100 p-3">
                                        <thead className="p-3">
                                            <th className="p-2 f-14">Status</th>
                                            <th className="p-2 f-14">Día</th>
                                            <th className="p-2 f-14">Horario</th>
                                    
                                            <th className="p-2 f-14">Tipo de Turno</th>
                                            <th className="p-2 f-14">Centro</th>
                                            <th className="p-2 f-14">Unidad Asistencial</th>
                                            <th className="p-2 f-14">Ambito Profesional</th>
                                        </thead>
                                        <tbody className="bg-light">
                                            {state._turnos.length ? filterDates()
                                            ?.filter((item) => state.filter === "" ? true : item.status === state.filter )
                                            ?.filter((item) => state.filter_center === "" ? true : item?.center?.id === parseInt(state.filter_center) )
                                            ?.map((turno, i)=>{
                                                if(i > state._turnos_show) return
                                                return (
                                                    <tr key={i}>
                                                        <td className="p-2">
                                                        {turno.status === "1" ? <div className="nu-tag nu-tag--green f-10 bolder px-2 py-1">Asignado</div> : <div className="nu-tag nu-tag--blue f-10 bolder px-2 py-1">Completado</div>}
                                                        </td>
                                                        <td className="p-2"><div className='f-12 bold ellipsis'>{UTCDateParser(new Date(turno.date).toUTCString(), true)}</div></td>
                                                        <td className="p-2"><div className="f-12 bold ellipsis">{turno.initialHour} - {turno.finalHour}</div></td>
                                                        <td className="p-2"><div className="f-12 bold ellipsis">{`Turno de ${turno?.type === '0' ? 'Acogida' : 'Cobertura'}`}</div></td>
                                                        <td className="p-2"><div className="f-12 bold ellipsis">{turno?.center?.name}</div></td>
                                                        <td className="p-2"><div className="f-12 bold ellipsis">{getAssistanceUnit(turno?.idAssistanceUnit)}</div></td>
                                                        <td className="p-2"><div className="f-12 bold ellipsis">{getProfessionalField(turno?.idProfessionalField)}</div></td>
                                                    </tr>
                                                )
                                            }) : (
                                                <tr key={'empty'}>
                                                    <td className="p-2"><div className="f-12 bold ellipsis"></div></td>
                                                    <td className="p-2"><div className="f-12 bold ellipsis"></div></td>
                                                    <td className="p-2"><div className="f-12 bold ellipsis"></div></td>
                                                    <td className="p-2"><div className="f-12 bold ellipsis"></div></td>
                                                    <td className="p-2"><div className="f-12 bold ellipsis">No hay turnos para mostrar.</div></td>
                                                    <td className="p-2"><div className="f-12 bold ellipsis"></div></td>
                                                    <td className="p-2"><div className="f-12 bold ellipsis"></div></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mt-4">
                            <div className="f-18 bolder">Documentos</div>
                        </div>

                        <div className='d-flex flex-wrap mt-2 '>
                            {
                                state._documents.map((item, i)=>{
                                console.log(item)
                                    return (
                                        <div key={i} className={`col-12 col-md-6 py-2 mt-md-3 ${i%2 ? 'ps-md-3' : ''}`}>
                                            <div className='bg-light r-10 p-3'>
                                                <div className='d-flex align-items-center pb-2 ps-1'>
                                                    <div className='f-16 bold'>{item.label}</div>
                                                    <div className='col'></div>
                                                    <Renderif isTrue={getDocument(item.type).file?.path !== 'Sin documento'}>
                                                        <div className='nu-icon nu-icon--mid'>
                                                            <BsCheckCircle color='green' size={30}></BsCheckCircle>
                                                        </div>
                                                    </Renderif>
                                                    <Renderif isTrue={getDocument(item.type).file?.path === 'Sin documento'}>
                                                        <div className='nu-icon nu-icon--mid'>
                                                            <FiInfo color='red' size={30}></FiInfo>
                                                        </div>
                                                    </Renderif>
                                                </div>
                                                <div className='d-flex  align-items-center'>
                                                    <div className='col pe-2'>
                                                        <input disabled placeholder={getDocument(item.type).file?.path} className='nu-input--light nu-input--disabled'></input>
                                                    </div>
                                                    <div className={`nu_btn  py-2 ${getDocument(item.type).file?.path !== 'Sin documento' ? 'nu_btn--primary' : 'nu_btn--disabled' }`} onClick={()=>{ 
                                                        if(getDocument(item.type).file?.path !== 'Sin documento'){
                                                            window.open(getDocument(item.type).file?.path, '_blank')
                                                        }
                                                    }}>
                                                        <div className='nu-icon nu-icon--mid me-2'>
                                                            <FaFolder size={18}></FaFolder>
                                                        </div>
                                                        <div className='nu_btn-text'>Ver archivo</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Renderif isTrue={state.modalToBlock}>
                            <div className='nu-modal'>
                                <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                                    <div className='d-flex'>
                                        <div className='col'></div>
                                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                            setState({...state, modalToBlock:false})
                                        }}>
                                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                                        </div>
                                    </div>
                                    <div className='col d-flex flex-column justify-content-center align-items-center'>
                                        <img width="100px" src='/assets/tick-circle-warning.png'></img>
                                        <div className='f-26 bold text-center'>Marca los motivos por los cuales este<br></br>profesional dejará de ver tus turnos publicados: </div>
                                    </div>

                                    <div className='py-1'></div>

                                    {restrictionReasons.map((item, i)=>{
                                        return (
                                            <div key={i} className='px-3'>
                                                <CheckBox text={item.label} value={item.type} onChange={(evt)=>{
                                                    if(evt.value){
                                                    setState({...state, reasons: [...state.reasons, item.type]})
                                                    } else {
                                                    state.reasons = state.reasons.filter((reason) => reason!==item.type)
                                                    }
                                                }}/>
                                            </div>
                                        )
                                    })}

                                    <div className='pt-2 f-14 text-center text-red'>Debes elegir al menos 1 motivo para poder bloquear</div>

                                    <div className='d-flex align-items-center border-bottom py-3'>
                                        <div className='col d-flex justify-content-center'>
                                            <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                evt.stopPropagation()
                                                setState({...state, modalToBlock:false})
                                            }}>
                                                <div className='nu_btn-text f-16'>Cancelar</div>
                                            </div>
                                        </div>
                                        <div className='col d-flex justify-content-center'>
                                            <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                evt.stopPropagation()
                                                onBlockProfesional()
                                            }}>
                                                <div className='nu_btn-text f-16'>Aceptar</div>
                                                <div className='nu_btn-overlay'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Renderif>

                        <div className="d-flex align-items-center mt-4">
                            <div className="f-18 bolder">Preferencias</div>
                        </div>

                        {
                            profile_data()?.map((item,j)=>{
                                if(state.user_data?.idUserType === 3 && j === 1)return;
                                if(state.user_data?.idUserType === 3 && j === 5)return;
                                if(state.user_data?.idUserType === 3 && j === 6)return;

                                return(

                                    <div key={j} className='bg-gray r-10 p-3 mt-3'>

                                        <div className='d-flex'>
                                            <div className='f-16 bold mb-3'>{item.title}</div>
                                            <div className='col'></div>
            
                                            <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                                if(state.editing_preferences === j){
                                                    setState({...state, editing_preferences:null}) 
                                                    return;
                                                }
                                                setState({...state, editing_preferences:j})
                                            }}>
                                                <div className='f-14 text-darkblue bold mx-2'>Ver</div>
                                                <div className='nu-icon nu-icon--mid cursor-pointer'>
                                                    <IoMdEye color={darkBlue} size={24}></IoMdEye>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap'>
                                           {
                                            item.tags.map((tag,i)=>{
                                                
                                                return (
                                                    <div key={i} className='nu-tag nu-tag--disabled  nu-tag nu-tag--active'>
                                                        <div>{tag}</div>

                                                        <Renderif isTrue={state.editing_preferences === j}>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoCloseCircleOutline size={24}></IoCloseCircleOutline>
                                                        </div>
                                                        </Renderif>
                                        
                                                    </div>
                                                )
                                            })
                                           }
                                        </div>

                                        <Renderif isTrue={state.editing_preferences === j}>
                                           
                                            
                                            <Renderif isTrue={j === 0}>
                                            
                                                <div className='d-flex flex-wrap mt-3'>
                                                    <div className={`nu-tag nu-tag--disabled cursor-pointer ${state.nurse_data.yearsOfExperience === '0' ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                        
                                                        state.nurse_data.yearsOfExperience = '0'
                                                        setState({...state})
                                                    
                                                    }}>
                                                        <div>Sin experiencia</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                    <div className={`nu-tag nu-tag--disabled cursor-pointer ${state.nurse_data.yearsOfExperience === '1' ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                        state.nurse_data.yearsOfExperience = '1'
                                                      
                                                        setState({...state})
                                                    }}>
                                                        <div>1-5 años</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                    <div className={`nu-tag nu-tag--disabled cursor-pointer ${state.nurse_data.yearsOfExperience === '2' ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                        state.nurse_data.yearsOfExperience = '2'
                                                      
                                                        setState({...state})
                                                    }}>
                                                        <div>6-10 años</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                    <div className={`nu-tag nu-tag--disabled cursor-pointer ${state.nurse_data.yearsOfExperience === '3' ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                        state.nurse_data.yearsOfExperience = '3'
                                                      
                                                        setState({...state})
                                                    }}>
                                                        <div>{'> 10 años'}</div>
                                                        <div className='nu-icon nu-icon--mid ms-2'>
                                                            <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 1 && state.user_data?.idUserType === 1}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {specialization.map((specialization, k)=>{
                                                    return (
                                                        <div key={k} className={`nu-tag nu-tag--disabled cursor-pointer ${state.nurse_data.idSpecialization === specialization.id ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            state.nurse_data.idSpecialization = specialization.id
                                                            setState({...state})
                                                        
                                                        }}>
                                                            <div>{specialization.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 2}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                
                                                {centerType.map((center, l)=>{
                                               
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.preferredCenter.some((item) => item.idCenterType === center.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            

                                                            if(state.preferredCenter.some((item) => item.idCenterType === center.id)){
                                                                state.preferredCenter = state.preferredCenter.filter((item)=> item.idCenterType !== center.id)
                                                            }else{

                                                                if(center.id === 1){
                                                                    state.preferredCenter = [];
                                                                }else{
                                                                    state.preferredCenter = state.preferredCenter.filter((item)=> item.idCenterType !== 1)
                                                                }

                                                                state.preferredCenter.push({
                                                                    id:0,
                                                                    idCenterType:center.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                            // state.nurse_data.idSpecialization = specialization.id
                                                            // setState({...state})
                                                        
                                                        }}>
                                                            <div>{center.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 3}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                {professionalField.filter((item) => item.id !== 7).map((field, l)=>{
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.preferredProfessionalField.some((item) => item.idProfessionalField === field.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.preferredProfessionalField.some((item) => item.idProfessionalField === field.id)){
                                                                state.preferredProfessionalField = state.preferredProfessionalField.filter((item)=> item.idProfessionalField !== field.id)
                                                            }else{

                                                                if(field.id === 1){
                                                                    state.preferredProfessionalField = [];
                                                                }else{
                                                                    state.preferredProfessionalField = state.preferredProfessionalField.filter((item)=> item.idProfessionalField !== 1)
                                                                }

                                                                state.preferredProfessionalField.push({
                                                                    id:0,
                                                                    idProfessionalField:field.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{field.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 4}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {assistanceUnit.filter((item) => item.id !== 16 && item.id !== 14).map((unit, l)=>{

                                               
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id)){
                                                                state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                                                            }else{


                                                                if(unit.id === 1){
                                                                    state.preferredAssistanceUnit = [];
                                                                }else{
                                                                    state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== 1)
                                                                }
                                                                

                                                                state.preferredAssistanceUnit.push({
                                                                    id:0,
                                                                    idAssistanceUnit:unit.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{unit.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 5 && state.user_data?.idUserType === 1}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {assistanceUnit.map((unit, l)=>{
                                                    if(l === 0) return;
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.educationAssistanceUnit?.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.educationAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id)){
                                                                state.educationAssistanceUnit = state.educationAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                                                            }else{

                                                                state.educationAssistanceUnit.push({
                                                                    id:0,
                                                                    idAssistanceUnit:unit.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{unit.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 6 && state.user_data?.idUserType === 1}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                {professionalField.map((field, l)=>{

                                                    if(l===0)return;
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.educationProfessionalField?.some((item) => item.idProfessionalField === field.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.educationProfessionalField.some((item) => item.idProfessionalField === field.id)){
                                                                state.educationProfessionalField = state.educationProfessionalField.filter((item)=> item.idProfessionalField !== field.id)
                                                            }else{

                                                                state.educationProfessionalField.push({
                                                                    id:0,
                                                                    idProfessionalField:field.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{field.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 7}>
                                                <div className='d-flex flex-wrap mt-3'>
                                                {assistanceUnit.map((unit, l)=>{

                                                    if(unit.id === 1){return}
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.assistanceUnitExperience.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.assistanceUnitExperience.some((item) => item.idAssistanceUnit === unit.id)){
                                                                state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item)=> item.idAssistanceUnit !== unit.id)
                                                            }else{


                                                                // if(unit.id === 1){
                                                                //     state.assistanceUnitExperience = [];
                                                                // }else{
                                                                //     state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item)=> item.idAssistanceUnit !== 1)
                                                                // }
                                                                

                                                                state.assistanceUnitExperience.push({
                                                                    id:0,
                                                                    assistanceUnitExperience:'1',
                                                                    idAssistanceUnit:unit.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{unit.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={j === 8}>
                                            <div className='d-flex flex-wrap mt-3'>
                                                {professionalField.map((field, l)=>{

                                                    if(field.id === 1) {return;}
                                                  
                                                    return (
                                                        <div key={l} className={`nu-tag nu-tag--disabled cursor-pointer ${state.professionalFieldExperience.some((item) => item.idProfessionalField === field.id) ? 'nu-tag nu-tag--active':''}`} onClick={()=>{
                                                            
                                                            if(state.professionalFieldExperience.some((item) => item.idProfessionalField === field.id)){
                                                                state.professionalFieldExperience = state.professionalFieldExperience.filter((item)=> item.idProfessionalField !== field.id)
                                                            }else{

                                                     
                                                                state.professionalFieldExperience.push({
                                                                    id:0,
                                                                    yearsOfExperience:'1',
                                                                    idProfessionalField:field.id,
                                                                    idUser:state.user_data?.id
                                                                }) 
                                                            }
                                                            setState({...state})
                                                       
                                                        
                                                        }}>
                                                            <div>{field.description}</div>
                                                            <div className='nu-icon nu-icon--mid ms-2'>
                                                                <IoAddCircleOutline size={24}></IoAddCircleOutline>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </Renderif>
                                          
                                        </Renderif>

                                        <Renderif isTrue={state.editing_preferences === j}>
                                            <div className='d-flex'>
                                                
                                                <div className='col'></div>

                                                {/* <div className='nu_btn nu_btn--primary' onClick={()=>{

                                                    if(j < 2){
                                                        if(state.user_data?.idUserType === 1){
                                                            updateNurseData();
                                                        }
                                                        if(state.user_data?.idUserType === 3){
                                                            updateTcaeData();
                                                        }
                                                       
                                                    }
                                                    if(j === 2){
                                                        updatePreferredCenter();
                                                    }
                                                    if(j === 3){
                                                        updatePreferredProfessionalField();
                                                    }
                                                    if(j === 4){
                                                        updatePreferredAssistanceUnit();
                                                    }
                                                    if(j === 5){
                                                        updateEducationAssistanceUnit();
                                                    }
                                                    if(j === 6){
                                                        updateEducationProfessionalField();
                                                    }
                                                    if(j === 7){
                                                        updateAssistanceUnitExperience();
                                                    }
                                                    if(j === 8){
                                                        updateProfessionalFieldExperience();
                                                    }
                                                    
                                                
                                                }}>
                                                    <div className='nu-icon nu-icon--mid me-1'>
                                                        <IoMdSave size={18}></IoMdSave>
                                                    </div>
                                                    <div className='nu_btn-text'>Guardar</div>
                                                </div> */}
                                            </div>
                                        </Renderif>
            
                                    </div>
                                )
                            })
                        }

                        <div className='bg-gray r-10 p-3 mt-3'>

                            <div className='d-flex'>
                                <div className='f-16 bold mb-3'>En qué Unidades Asistenciales tienes experiencia</div>
                                <div className='col'></div>

                                <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                    if(state.editing_preferences === 'UAE'){
                                        setState({...state, editing_preferences:null}) 
                                        return;
                                    }
                                    setState({...state, editing_preferences:'UAE'})
                                }}>
                                    <div className='f-14 text-darkblue bold mx-2'>Ver</div>
                                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                                        <IoMdEye color={darkBlue} size={24}></IoMdEye>
                                    </div>
                                </div>
                            </div>
                                
                            <Renderif isTrue={state.editing_preferences === 'UAE'}>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='col px-3 px-md-5 event-none'>
                                    <CheckBox onChange={(evt)=>{
                                    
                                        if(evt.value){
                                            state.assistanceUnitExperience = []
                                        }
                                        setState({...state})
                                    }} checked={state.assistanceUnitExperience.length === 0 ? true: false} text="No tengo experiencia en ninguna Unidad Asistencial" ></CheckBox>
                                </div>
                                <div className='d-flex my-3 px-3 px-md-5'>
                                    <div className='col'></div>
                                    <div className='col d-flex justify-content-around '>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                    </div>
                                    <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                    </div>
                                    </div>
                                </div>
                                { 
                                    assistanceUnit?.map((option, i)=>{

                                        if(i === 0){
                                            return;
                                        }
                                        return ( 
                                        
                                            <div key={i} className={`d-flex px-3 px-md-5 py-1 event-none ${i%2 == 0  ? 'bg-white' : ''}`}>
                                                <div className='col'>
                                                    <div className='f-14 text-dark bold ps-4'>{option.description}</div>
                                                </div>
                                        
                                                <div className='col d-flex justify-content-evenly '>
                                                    
                                                <CheckBox text="" value="0" checked={assistanceUnitOptionChecked("0", option)}  onChange={(evt)=>{
                                                    
                                                        let unit = {
                                                        idUser:state.user_data?.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    
                                                    if(evt.value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                            

                                                }}></CheckBox>
                                                <CheckBox text="" value="1" checked={assistanceUnitOptionChecked("1", option)}  onChange={(evt) => {
                                                        
                                                        let unit = {
                                                        idUser:state.user_data?.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                                }} ></CheckBox>
                                                <CheckBox text="" value="2" checked={assistanceUnitOptionChecked("2", option)}  onChange={(evt) => {
                                                        
                                                        let unit = {
                                                        idUser:state.user_data?.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                                }} ></CheckBox>

                                                <div className='d-none'>
                                                    <NuComboBox 
                                                    showkey={false} 
                                                    key={i} 
                                                    multiple={false} 
                                                    checks={yearsExperienceOptions} 
                                                    onChange={(evt)=>{

                                                    

                                                        let unit = {
                                                            idUser:state.user_data?.id,
                                                            idAssistanceUnit:option.id,
                                                            yearsOfExperience:evt[0].key.id.toString(),

                                                        }
                                                        if(evt[0].value){
                                                            state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                            state.assistanceUnitExperience.push(unit);  
                                                        }else{
                                                            state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                            
                                                        }
                                                        
                                                    
                                                        setState({...state})
                                                            
                                                    
                                                    }}>
                                                    </NuComboBox>
                                                </div>
                                                </div>

                                            </div>
                                        
                                    
                                        
                                        )
                                    })
                                }
                                {/* <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{                        
                                        updateAssistanceUnitExperience();                                                    
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Guardar</div>
                                    </div>
                                </div> */}
                            </Renderif>

                        </div>

                        <div className='bg-gray r-10 p-3 mt-3'>

                           
                            <div className='d-flex'>
                                <div className='f-16 bold mb-3'>En qué  ámbitos profesionales tienes experiencia</div>
                                <div className='col'></div>

                                <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                    if(state.editing_preferences === 'APE'){
                                        setState({...state, editing_preferences:null}) 
                                        return;
                                    }
                                    setState({...state, editing_preferences:'APE'})
                                }}>
                                    <div className='f-14 text-darkblue bold mx-2'>Ver</div>
                                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                                        <IoMdEye color={darkBlue} size={24}></IoMdEye>
                                    </div>
                                </div>
                            </div>

                            <Renderif isTrue={state.editing_preferences === 'APE'}>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='col px-3 px-md-5 event-none'>
                                    <CheckBox onChange={(evt)=>{
                                    
                                        if(evt.value){
                                            state.professionalFieldExperience = []
                                        }
                                        setState({...state})
                                    }} checked={state.professionalFieldExperience.length === 0 ? true: false} text="No tengo experiencia en ningún ámbito profesional"></CheckBox>
                                </div>
                                <div className='d-flex my-3 px-3 px-md-5'>
                                    <div className='col'></div>
                                    <div className='col d-flex justify-content-around '>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                    </div>
                                    <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                    </div>
                                    </div>
                                </div>
                        
                                { 
                                    professionalField.filter((item)=> item.id !== 7).map((option, i)=>{
                                            if(i === 0){
                                            return;
                                        }
                                        return ( 
                                        
                                            <div key={i} className={`d-flex px-3 px-md-5 py-1 event-none ${i%2 == 0  ? 'bg-white' : ''}`}>
                                                <div className='col'>
                                                    <div className='f-14 text-dark bold ps-4'>{option.description}</div>
                                                </div>
                                        
                                                <div className='col d-flex justify-content-evenly '>
                                                <CheckBox text="" value="0" checked={optionChecked("0", option)}  onChange={(evt)=>{
                                                
                                                        let unit = {
                                                        idUser:state.user_data?.id,
                                                        idProfessionalField:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    
                                                    if(evt.value){
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        state.professionalFieldExperience.push(unit);  
                                                    }else{
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        
                                                    }
                                            
                                                    setState({...state})
                                            

                                                }}></CheckBox>
                                                <CheckBox text="" value="1" checked={optionChecked("1", option)}  onChange={(evt) => {
                                                    
                                                        let unit = {
                                                        idUser:state.user_data?.id,
                                                        idProfessionalField:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        state.professionalFieldExperience.push(unit);  
                                                    }else{
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        
                                                    }
                                                
                                                    setState({...state})
                                                }} ></CheckBox>
                                                <CheckBox text="" value="2" checked={optionChecked("2", option)}  onChange={(evt) => {
                                                    
                                                        let unit = {
                                                        idUser:state.user_data?.id,
                                                        idProfessionalField:option.id,
                                                        yearsOfExperience:evt.key,

                                                    }
                                                    if(evt.value){
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        state.professionalFieldExperience.push(unit);  
                                                    }else{
                                                        state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        
                                                    }
                                                    
                                                    setState({...state})
                                                }} ></CheckBox>

                                                    <div className='d-none'>
                                                    <NuComboBox 
                                                        showkey={false} 
                                                        key={i} 
                                                        multiple={false} 
                                                        checks={yearsExperienceOptions} 
                                                        onChange={(value)=>{
                                                        

                                                            let unit = {
                                                                idUser:state.user_data?.id,
                                                                idProfessionalField:option.id,
                                                                yearsOfExperience:value[0].key.id.toString(),

                                                            }
                                                            if(value[0].value){
                                                                state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                                state.professionalFieldExperience.push(unit);  
                                                            }else{
                                                                state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                            }
                                                            
                                                            
                                                                
                                                        
                                                        }}>
                                                    </NuComboBox>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                    
                                        
                                        )
                                    })
                                }
                                {/* <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{                        
                                        updateProfessionalFieldExperience();                                                    
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Guardar</div>
                                    </div>
                                </div> */}
                            </Renderif>

                        </div>



                     

                    </div>
                   
                </div>



            </div>
 
            <div className='py-5 my-5'> </div>
    
        </div>

   
   
                      
     
    </div>
    </>
  )
}
