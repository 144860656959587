import React, { useContext, useEffect, useState } from 'react'
import { createContext } from "react";
import { useNavigate } from 'react-router-dom';
import NuLoading from '../components/nu_loading';
import { Service } from '../services/api';
import { documentsNurse, documentsTCAE } from '../utils';
import Renderif from '../utils/renderif';
import { AuthContext } from './auth_context';
import { NotificationContext } from './notification_context';

export const GlobalContext = createContext();

export default function GlobalContextProvider({children}) {

  const navigate = useNavigate();
  const {notify} = useContext(NotificationContext);
  const {user, user_data} = useContext(AuthContext)

    const [state, setState] = useState({
        loading:false,
        loadingDocuments:false,
        center:[],
    
        assistanceUnit:[],
        specialization:[],
        professionalField:[],
        centerType:[],
        documents:[],
        valoraciones:[]
    })

    let documents = []


    

    useEffect(()=>{

      
        const init = async() => {
            setState({...state, loading:true, loadingDocuments:true})
            state.documents = []
            
            let _center = await Service('GET', 'center/getAll');
            let _centerType = await Service('GET', 'centerType/getAll');
            let _assistanceUnit = await Service('GET', `assistanceUnit/getAll`);
            let _specialization = await Service('GET', 'specialization/getAll')
            let _professionalField = await Service('GET', `professionalField/getAll`);
            let _valoraciones = await Service('GET', 'rating/getAll?idUser='+user?.id);
      
         
       

            setState({
                ...state,
                loading:false,
                loadingDocuments:true,
                center:_center.data.list,
                assistanceUnit:_assistanceUnit.data.list,
                specialization:_specialization.data.list,
                professionalField:_professionalField.data.list,
                centerType:_centerType.data.list,
                valoraciones:_valoraciones.data.list
            })


            if(user?.idUserType < 4){
                if(user?.idUserType === 1){
                    documents = documentsNurse;
                }else{
                    documents = documentsTCAE;
                }
                for (let i = 0; i < documents.length; i++) {
                    let _doc = await Service('GET', `document/view/${user.id}/${documents[i].type}`);
              
                    if(_doc.status === 201){
                        let document = {
                            type:documents[i].type,
                            file:{path:_doc.data.url},
                            label:''
                        }
                        if(!state.documents.some((item)=>item.type === documents[i].type)){
                            state.documents.push(document)
                        }
                    }
                }
            }

         

            setState({
                ...state,
                loading:false,
                loadingDocuments:false,
                center:_center.data.list,
                assistanceUnit:_assistanceUnit.data.list,
                specialization:_specialization.data.list,
                professionalField:_professionalField.data.list,
                centerType:_centerType.data.list,
                valoraciones:_valoraciones.data.list
            })

         
        }
        init();
        return () =>{}
      
    },[])

    const getRating = () => {

        let sum = 0;
        state.valoraciones?.map((item)=>{
            sum = sum + parseInt(item.stars)
        })

        return {
            rate:user_data?.stars?.toFixed(2),
            valoraciones:state.valoraciones.length
        }
    }

    const loadCenters = async () => {
        let _center = await Service('GET', 'center/getAll')
     
        setState({
            ...state,
            center:_center.data.list,
        })
        return _center.data.list;
    }

    const loadDocuments = async () => {
        setState({...state, loading:true, loadingDocuments:true})
        state.documents = []
        if(user?.idUserType === 1){
            documents = documentsNurse;
        }else{
            documents = documentsTCAE;
        }
        for (let i = 0; i < documents.length; i++) {
            let _doc = await Service('GET', `document/view/${user.id}/${documents[i].type}`);
      
            if(_doc.status === 201){
                let document = {
                    type:documents[i].type,
                    file:{path:_doc.data.url},
                    label:''
                }
                state.documents.push(document)
                
            }
        }
        setState({
            ...state,
            loading:false,
            loadingDocuments:false,
            documents:state.documents
           
        })
        return state.documents;
    }

    const upLoadFile = async (file, type) => {

        let body = new FormData();
        body.append("", file);

        return await Service('POSTMEDIA', `document/upload/${type}`, body )
    
    }
    const upLoadDocument = async (file, type) => {

        
            

        let _upload = await upLoadFile(file, type )
      
        if(_upload.status === 201){

            let _doc = await Service('GET', `document/view/${user.id}/${type}`);
           

            let document = {
                type,
                file:{path:_doc.data.url},
                label:''
            }
            if(state.documents.some((item) => item.type === state.active_file_type)){
                state.documents = state.documents.filter((item)=>item.type !== state.active_file_type)
            }
            if(state.documents.filter((item)=>item.type === document.type).length === 0){
                state.documents.push(document);
            }
            setState({...state})

            notify({title:'Listo!', message:'Documento cargado exitosamente.' , type:'success'})

        }else{
            notify({title:'Error!', message:'Eror al cargar documento.' , type:'error'})
        }
        return _upload;


    }

    const renderTitle = (center, turnoType) => {
        if(center === '') return '';
        if(turnoType === '') return '';

        let _centerSelected = state.center?.filter((item)=>item.id === parseInt(center))[0]?.name
     
        
        let _turnoTyoe = turnoType == '0' ? 'Acogida' : 'Cobertura' 
        let title = 'Turno de '+ _turnoTyoe + ' en '+_centerSelected;
        return title;
    }
    const getCenter = (center) => {
      
        return state.center?.filter((item)=>item.id === parseInt(center))[0];
    }
    const getAssistanceUnit = (id) => {
        return state.assistanceUnit?.filter((item)=>item.id === parseInt(id))[0]?.description 
    } 
    const getProfessionalField = (id) => {
        if(id === 1){
            return "No aplica"
        }
        return state.professionalField?.filter((item)=>item.id === parseInt(id))[0]?.description 
    }
    const getSpecialization = (id) => {
        if(id === 1){
            return "No requiere especialidad"
        }
        return state.specialization?.filter((item)=>item.id === parseInt(id))[0]?.description 
    }
    const getCenterType = (id) => {
        return state.centerType?.filter((item)=>item.id === parseInt(id))[0]?.description 
    }
    const getProfesion = (id) => {
        console.log(id)
        switch (id) {
            case '0':
                
                return 'Enfermera/o'
            case '1':

                return 'TCAE'
           
        
            default:
                return ''
        }
    }
    const getUserType = (id) => {
    
        switch (id) {
            case '1':
    
                return 'Enfermera/o'
            case '3':

                return 'TCAE'
           
        
            default:
                return ''
        }
    }
    const getStatus = (status)  => {
        switch (status) {
            case '0':
                return 'Pendiente de asignar'        
            default:
                break;
        }
    }

    const getShiftType = (type) => {

        switch (type) {
            case '0':
                return 'Acogida'    
                case '1':
                    return 'Cobertura'        
            default:
                return ""
        }
        
    }



 


  

  return (
    <GlobalContext.Provider value={{
        ...state,
        renderTitle,
        getCenter,
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType,
        getProfesion,
        loadCenters,
        upLoadFile,
        upLoadDocument,
        loadDocuments,
        getRating,
        getUserType,
        getStatus,
        getShiftType
      }}>

        <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif>



        {children}

    </GlobalContext.Provider>
  )
}
